import React from "react"
import { useRouteMatch, Link } from "react-router-dom"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { Theme, Button } from "@material-ui/core"
import { typography } from "../../services/theme/typography"

interface RouteParams {
  affinityGroupId: string
  coachId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      borderRadius: 0,
      borderBottom: `3px solid transparent`,
      padding: theme.spacing(2, 3),
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.common.black,
      ...typography.circularXXMedium,
    },
    activeTab: {
      borderBottom: `3px solid #1F70CE`,
      color: "#1F70CE",
      ...typography.circularXXBold,
    },
  }),
)

const AffinityTab = ({
  route,
  isActive,
  label,
}: {
  route: string
  isActive?: boolean
  label: string
}) => {
  const classes = useStyles()
  const matchAffinityRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId",
  )
  const matchCurrentRoute = useRouteMatch<RouteParams>(
    `/pilot-trainer/:coachId/affinity/:affinityGroupId${route}`,
  )

  const { affinityGroupId, coachId } = matchAffinityRoute?.params || {}

  return (
    <Link to={`/pilot-trainer/${coachId}/affinity/${affinityGroupId}${route}`}>
      <Button
        className={clsx(classes.tab, {
          [classes.activeTab]: matchCurrentRoute?.isExact || isActive,
        })}>
        {label}
      </Button>
    </Link>
  )
}

export { AffinityTab }
