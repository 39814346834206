import React from "react"
import { Link } from "react-router-dom"
import { useRouteMatch } from "react-router-dom"
import { Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { typography } from "../../services/theme/typography"

interface StyleProps {
  selected?: boolean
}

interface RouteMatchParams {
  coachId: string
  groupId: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    buttonContainer: {
      minHeight: theme.spacing(6),
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(1),
      alignItems: "center",
      borderRadius: theme.spacing(1),
      backgroundColor: (props) => {
        if (props.selected) {
          return theme.palette.common.white
        }
        return "transparent"
      },
      "&:hover": {
        backgroundColor: (props) => {
          if (props.selected) {
            return theme.palette.common.white
          }
          return "rgba(0, 0, 0, 0.04)"
        },
      },
    },
    iconContainer: {
      minWidth: 0,
      marginRight: theme.spacing(2),
      "& svg": {
        fill: (props) => {
          if (props.selected) {
            return "#1F70CE"
          }
          return theme.palette.common.white
        },
        stroke: (props) => {
          if (props.selected) {
            return "#1F70CE"
          }
          return theme.palette.common.white
        },
      },
    },
    labelRoot: {
      margin: 0,
      flex: "initial",
    },
    labelPrimary: {
      margin: 0,
      fontSize: theme.spacing(2),
      color: (props) => {
        if (props.selected) {
          return "#1F70CE"
        }
        return theme.palette.common.white
      },
      fontFamily: (props) => {
        if (props.selected) {
          return typography.circularXXBold.fontFamily
        }
        return typography.circularXXMedium.fontFamily
      },
    },
    selectedListItem: {
      backgroundColor: "#F1F1F1 !important",
    },
  }),
)

interface NavOptionButtonProps {
  selected?: boolean
  to: string
  icon?: React.ReactNode
  label: string
  children?: React.ReactNode
}

const NavOptionButton = ({
  selected,
  to,
  icon,
  label,
  children,
}: NavOptionButtonProps) => {
  const matchRoute = useRouteMatch<RouteMatchParams>(to)
  const classes = useStyles({ selected: matchRoute?.isExact || selected })

  return (
    <ListItem
      to={to}
      component={Link}
      button
      className={classes.buttonContainer}
      classes={{
        selected: classes.selectedListItem,
      }}>
      {icon && (
        <ListItemIcon className={classes.iconContainer}>{icon}</ListItemIcon>
      )}

      <ListItemText
        classes={{
          root: classes.labelRoot,
          primary: classes.labelPrimary,
        }}
        primary={label}
      />

      {children}
    </ListItem>
  )
}

export { NavOptionButton }
