import React from "react"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switcherContainer: {
      display: "flex",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

const ActiveSlider = ({ tileID }: { tileID: string }) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()

  const { tiles, apiAdminToggleActiveTiles } = adminMslEventStoreModel
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const currentTile = tiles.find((tile) => tile.id === tileID)
  const { active, setIsActive } = currentTile

  const toggleActiveTile = async () => {
    try {
      setIsActive(!active)

      await apiAdminToggleActiveTiles({
        ...currentTile,
        active: !active,
      })
      setSnackbarSeverity("success")
      const activeStatus = !active ? "activated" : "deactivated"
      setSnackbarText(`Successfully ${activeStatus} whats next tile !`)
      setShowSnackbar(true)
    } catch (error) {
      setIsActive(!active)
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <div className={classes.switcherContainer}>
      <CustomSwitch
        checked={active}
        onChange={toggleActiveTile}
        activeLabel=""
        inActiveLabel=""
        labelSwitchSpacing={1}
        activeViewColor={"#00CC7E"}
        inActiveViewColor={"#808080"}
      />
      <Typography ml={1} className={classes.label}>
        {active ? "On" : "Off"}
      </Typography>
    </div>
  )
}

export { ActiveSlider }
