import { ApisauceInstance, create } from "apisauce"
import axios from "axios"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"
import { recordError, ErrorType } from "../../utils/exceptions"
import * as Types from "./api.types"
import { __DEV__ } from "../../config/constants"
import { clear as clearStorage } from "../../utils"

export class Api {
  apisauce: ApisauceInstance
  config: ApiConfig
  siteId: string | null
  personId: string | null

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    })

    this.siteId = null
    this.personId = null

    if (__DEV__) {
      //@ts-ignore
      this.apisauce.addMonitor(console.tron.apisauce)
    }

    this.apisauce.addMonitor((response) => {
      if (response.status === 401) {
        clearStorage()
        window.location.href = "/login"
      }
    })
  }

  setAuthorizationHeader = (newToken?: string) => {
    if (newToken) {
      this.apisauce.setHeader("Authorization", `Bearer ${newToken}`)
    } else {
      this.apisauce.deleteHeader("Authorization")
    }
  }

  setSiteId = (siteId: string) => {
    this.siteId = siteId
  }

  setPersonId = (personId: string) => {
    this.personId = personId
  }

  processResponse = async (
    response: any,
    nestedOnlyOnce?: boolean,
  ): Promise<any> => {
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        recordError(ErrorType.API, {
          ...response,
          message: response.config.url,
        })

        if (problem.kind === "unauthorized") {
        }

        // ensure data exists
        if (!("data" in problem)) {
          problem["data"] = {}
        }

        return problem
      }
    }

    //sometimes the success response is empty and we need to keep data optional
    let data = response?.data?.data
    if (nestedOnlyOnce) {
      data = response?.data
    }

    return { kind: "ok", data: data }
  }

  login = async (
    email: string,
    password: string,
  ): Promise<Types.LoginResult> => {
    const response = await this.apisauce.post(`/auth/login`, {
      email,
      password,
    })
    return this.processResponse(response)
  }

  signup = async (params: Types.SignupParams): Promise<Types.SignupResult> => {
    const response = await this.apisauce.post("auth/signup", params)
    return this.processResponse(response)
  }

  getPrograms = async (): Promise<Types.GetProgramsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${this.siteId}/programs`,
    )
    return this.processResponse(response)
  }

  getCohorts = async (): Promise<Types.GetCohortsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${this.siteId}/cohorts`,
    )
    return this.processResponse(response)
  }

  getCohort = async (id: string): Promise<Types.GetCohortResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${id}`,
    )
    return this.processResponse(response)
  }

  createCohort = async (
    params: Types.CreateCohortParams,
  ): Promise<Types.CreateCohortResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts`,
      params,
    )
    return this.processResponse(response)
  }

  patchCohort = async (
    id: string,
    params: Types.PatchCohortParams,
  ): Promise<Types.PatchCohortResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${id}`,
      params,
    )
    return this.processResponse(response)
  }

  updateCoachMotivation = async (
    cohortId: string,
    params: Types.UpdateCoachMotivationParams,
  ): Promise<Types.UpdateCoachMotivationResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/cohorts/${cohortId}/motivation`,
      params,
    )
    return this.processResponse(response)
  }

  updatePerson = async (
    params: Types.UpdatePersonParams,
  ): Promise<Types.UpdatePersonResult> => {
    const response = await this.apisauce.put(`/person/${params.id}`, params, {
      baseURL: `${this.config.url}`.replace("/pro", ""),
    })
    return this.processResponse(response, true)
  }

  getAvatarsList = async (): Promise<Types.GetAvatarsListResult> => {
    const response = await this.apisauce.get(
      `/avatars`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  updatePersonAvatar = async (
    params: Types.UpdatePersonAvatarParams,
  ): Promise<Types.UpdatePersonAvatarResult> => {
    const response = await this.apisauce.put(
      `/person/${this.personId}/avatar`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  updateCohortSession = async (
    params: Types.UpdateCohortSessionParams,
  ): Promise<Types.UpdateCohortSessionResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/sessions/${params.sessionId}`,
      params,
    )
    return this.processResponse(response)
  }

  inviteCoachesToCohort = async (
    params: Types.AddCoachesToCohortParams,
  ): Promise<Types.AddCoachesToCohortResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/invite_coaches`,
      params,
    )
    return this.processResponse(response)
  }

  deleteCoachFromCohort = async (params: Types.DeleteCoachFromCohortParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/coaches/${params.coachId}`,
    )
    return this.processResponse(response)
  }

  resendCoachInvite = async (params) => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/coaches/${params.coachID}/resend_invitation`,
      params,
    )
    return this.processResponse(response)
  }

  inviteParticipantsToCohort = async (
    params: Types.AddParticipantsToCohortParams,
  ): Promise<Types.AddParticipantsToCohortResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/invite_participants`,
      params,
    )
    return this.processResponse(response)
  }

  resendParticipantInvite = async (params) => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/participants/${params.participantID}/resend_invitation`,
      params,
    )
    return this.processResponse(response)
  }

  createSession = async (
    params: Types.CreateSessionParams,
  ): Promise<Types.CreateSessionResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/sessions`,
      params,
    )
    return this.processResponse(response)
  }

  deleteSession = async (params: Types.DeleteSessionParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/sites/${this.siteId}/cohorts/${params.cohortId}/sessions/${params.sessionId}`,
    )
    return this.processResponse(response)
  }

  inviteAdminsToSite = async (
    params: Types.AddAdminsToSiteParams,
  ): Promise<Types.AddParticipantsToCohortResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/invite_admins`,
      params,
    )
    return this.processResponse(response)
  }

  resendAdminInvite = async (params) => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${this.siteId}/admins/${params.adminID}/resend_invitation`,
    )
    return this.processResponse(response)
  }

  getSites = async (): Promise<Types.GetSitesResult> => {
    const response = await this.apisauce.get(`/person/${this.personId}/sites`)
    return this.processResponse(response)
  }

  getSite = async (id: string): Promise<Types.GetSiteResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${id}`,
    )
    return this.processResponse(response)
  }

  createSite = async (
    params: Types.CreateSiteParams,
  ): Promise<Types.CreateSiteResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites`,
      params,
    )
    return this.processResponse(response)
  }

  deleteSite = async (id: string): Promise<Types.GetSiteResult> => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/sites/${id}`,
    )
    return this.processResponse(response)
  }

  getCohortChatsLists = async (
    params: Types.GetCohortChatsListParams,
  ): Promise<Types.GetCohortChatsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/chats`,
      params,
    )
    return this.processResponse(response)
  }

  getCohortGroupChats = async (
    params: Types.GetCohortGroupChatsParams,
  ): Promise<Types.GetCohortChatsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/chats/group`,
      params,
    )
    return this.processResponse(response)
  }

  getCohortDirectChats = async (
    params: Types.GetCohortDirectChatsParams,
  ): Promise<Types.GetCohortDirectChatsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/chats/${params.roomId}`,
      params,
    )
    return this.processResponse(response)
  }

  createCohortChat = async (
    params: Types.CreateCohortChatParams,
  ): Promise<Types.CreateCohortChatResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/cohorts/${params.cohortId}/chats`,
      params,
    )
    return this.processResponse(response)
  }

  getPerson = async (personID: string): Promise<Types.GetPersonResult> => {
    const response = await this.apisauce.get(
      `/person/${personID}`,
      {},
      {
        baseURL: "https://devapi.freshtri.com/api/v2",
      },
    )
    return this.processResponse(response)
  }

  getParticipantInCohort = async (
    params: Types.GetPersonLogsParams,
  ): Promise<Types.GetPersonResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/participants/${params.participantId}`,
    )
    return this.processResponse(response)
  }

  getPersonLogs = async (
    params: Types.GetPersonLogsParams,
  ): Promise<Types.GetPersonLogsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/participants/${params.participantId}/logs`,
    )
    return this.processResponse(response)
  }

  getCohortLogs = async (
    params: Types.GetCohortLogsParams,
  ): Promise<Types.GetCohortlogsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/logs`,
    )
    return this.processResponse(response)
  }

  verifyPersonLog = async (
    params: Types.VerifyPersonLogsParams,
  ): Promise<Types.GetPersonLogsResult> => {
    const response = await this.apisauce.put(
      `/person/${this.personId}/logs/${params.logId}/verify`,
    )
    return this.processResponse(response)
  }

  deleteVerifyPersonLog = async (
    params: Types.VerifyPersonLogsParams,
  ): Promise<Types.GetPersonLogsResult> => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/logs/${params.logId}/verify`,
    )
    return this.processResponse(response)
  }

  deleteChatMessage = async (params: Types.DeleteChatMessageParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/chat/${params.messageId}`,
    )
    return this.processResponse(response)
  }

  forgotPassword = async (
    email: string,
  ): Promise<Types.ForgotPasswordResult> => {
    const response = await this.apisauce.post(`/auth/init_reset_password`, {
      email,
    })
    return this.processResponse(response)
  }

  resetPassword = async (
    params: Types.ResetPasswordParams,
  ): Promise<Types.ResetPasswordResult> => {
    const response = await this.apisauce.post(`/auth/reset_password`, params)
    return this.processResponse(response)
  }

  changePassword = async (
    params: Types.ChangePasswordParams,
  ): Promise<Types.ChangePasswordResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/reset_password`,
      params,
    )
    return this.processResponse(response)
  }

  contactAuthenticatedSupport = async (
    params: Types.ContactAuthenticatedSupportParams,
  ): Promise<Types.ContactSupportResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/support/pro`,
      params,
      {
        //custom base url for contact authenticated support
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  contactUnAuthenticatedSupport = async (
    params: Types.ContactUnAuthenticatedSupportParams,
  ): Promise<Types.ContactSupportResult> => {
    const response = await this.apisauce.post(`/auth/support/pro`, params, {
      //custom base url for contact unauthenticated support
      baseURL: `${this.config.url}`.replace("/pro", ""),
    })
    return this.processResponse(response)
  }

  getParticipantTriHistory = async (
    params: Types.GetParticipantTriHistoryParams,
  ): Promise<Types.GetParticipantTriHistoryResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/participants/${params.participantId}/tri_history`,
    )
    return this.processResponse(response)
  }

  getParticipantAttendanceHistory = async (
    params: Types.GetParticipantAttendanceHistoryParams,
  ): Promise<Types.GetParticipantAttendanceHistoryResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${params.siteId}/cohorts/${params.cohortId}/participants/${params.participantId}/attendance`,
    )
    return this.processResponse(response)
  }

  getCohortSessionsAttendance = async (
    params: Types.GetCohortSessionsAttendanceParams,
  ): Promise<Types.GetCohortSessionsAttendanceResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/sites/${params.siteId}/cohorts/${params.cohortId}/sessions/${params.sessionId}/attendance`,
    )
    return this.processResponse(response)
  }

  deleteFtAdmin = async (params: Types.DeleteFtAdminParams): Promise<any> => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/sites/${params.siteId}/admins/${params.siteAdminPersonId}`,
    )
    return this.processResponse(response)
  }

  setAttendance = async (params: Types.SetAttendanceParams): Promise<any> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/sites/${params.siteId}/cohorts/${params.cohortId}/sessions/${params.sessionId}/attendance`,
      params,
    )
    return this.processResponse(response)
  }

  getCurrentCoachMeta = async (
    params: Types.GetCurrentCoachMetaParams,
  ): Promise<Types.GetCurrentCoachMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/coach/${params.coachID}/meta`,
    )
    return this.processResponse(response)
  }
  getPilotCoachChatMeta = async (): Promise<Types.GetPilotChatMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/chats/meta`,
    )
    return this.processResponse(response)
  }
  getPilotCoachGroupsMeta = async (): Promise<Types.GetPilotCoachGroupsMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/coach/meta`,
    )
    return this.processResponse(response)
  }
  getCurrentGroupMeta = async (
    params: Types.GetCurrentGroupMetaParams,
  ): Promise<Types.GetCurrentGroupMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/group/${params.groupID}/meta`,
    )
    return this.processResponse(response)
  }

  getCurrentGroupUnreadMessagesMeta = async (
    params: Types.GetCurrentGroupUnreadMessagesMetaParams,
  ): Promise<Types.GetCurrentGroupUnreadMessagesMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/group/${params.groupID}/unanswered_chat_participants/meta`,
    )
    return this.processResponse(response)
  }
  getCurrentGroupInactiveParticipants = async ({
    groupID,
    ...params
  }: Types.GetCurrentGroupInactiveParticipantsParams): Promise<Types.GetCurrentGroupInactiveParticipantsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/group/${groupID}/inactive_participants?createdSince=2015-09-15T11:53:41Z&`,
      params,
    )
    return this.processResponse(response)
  }
  currentGroupSearchParticipants = async ({
    groupID,
    ...params
  }: Types.CurrentGroupSearchParticipantsParams): Promise<Types.CurrentGroupSearchParticipantsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/group/${groupID}/search/participants`,
      params,
    )
    return this.processResponse(response)
  }
  getCurrentGroupSavedChats = async ({
    groupID,
  }: Types.GetCurrentGroupSavedChatsParams): Promise<Types.GetCurrentGroupSavedChatsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/groups/${groupID}/chats/saved_chats`,
      {},
    )
    return this.processResponse(response)
  }
  getAffinityGroupPosts = async ({
    groupID,
    ...params
  }: Types.GetAffinityGroupPostsParams): Promise<Types.GetAffinityGroupPostsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/group/${groupID}/affinity_group_chat`,
      params,
    )
    return this.processResponse(response, true)
  }
  pilotCoachMuteAffinityPost = async ({
    groupID,
    postId,
    ...params
  }: Types.PilotCoachMuteAffinityPostParams): Promise<Types.PilotCoachMuteAffinityPostResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/groups/${groupID}/coach_chats/${postId}/mute`,
      params,
    )
    return this.processResponse(response, true)
  }
  pilotCoachUnMuteAffinityPost = async ({
    groupID,
    postId,
    ...params
  }: Types.PilotCoachMuteAffinityPostParams): Promise<Types.PilotCoachMuteAffinityPostResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/groups/${groupID}/coach_chats/${postId}/unmute`,
      params,
    )
    return this.processResponse(response, true)
  }
  pilotCoachBanAffinityMember = async ({
    groupID,
    memberId,
  }: Types.PilotCoachBanAffinityMemberParams): Promise<Types.PilotCoachBanAffinityMemberResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/groups/${groupID}/members/${memberId}/ban`,
      {},
    )
    return this.processResponse(response, true)
  }
  pilotCoachUnBanAffinityMember = async ({
    groupID,
    memberId,
  }: Types.PilotCoachBanAffinityMemberParams): Promise<Types.PilotCoachBanAffinityMemberResult> => {
    const response = await this.apisauce.patch(
      `/person/${this.personId}/groups/${groupID}/members/${memberId}/unban`,
      {},
    )
    return this.processResponse(response, true)
  }

  getPilotChatConversation = async ({
    coachID,
    roomId,
    ...params
  }: Types.GetPilotChatConversationParams): Promise<Types.GetPilotChatConversationResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/coaches/${coachID}/chats/participants/${roomId}`,
      params,
    )
    return this.processResponse(response)
  }

  pilotCoachAddNewMessage = async (
    params: Types.PilotCoachAddNewMessageParams,
  ): Promise<Types.PilotCoachAddNewMessageResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/chats`,
      params,
    )
    return this.processResponse(response)
  }

  pilotCoachDeleteMessage = async (params: Types.DeleteChatMessageParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/chats/${params.messageId}`,
    )
    return this.processResponse(response)
  }

  pilotCoachSearchTrainContent = async (
    params: Types.PilotCoachSearchTrainContentParams,
  ): Promise<Types.PilotCoachSearchTrainContentResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/train/episodes/search`,
      params,
    )
    return this.processResponse(response, true)
  }

  getParticipantDetails = async (
    params: Types.GetParticipantDetailsParams,
  ): Promise<Types.GetParticipantDetailsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}`,
    )
    return this.processResponse(response)
  }
  getParticipantTriHistoryNonDpp = async (
    params: Types.GetParticipantTriHistoryNonDppParams,
  ): Promise<Types.GetParticipantTriHistoryNonDppResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}/tri_history`,
    )
    return this.processResponse(response)
  }
  getParticipantBiometrics = async (
    params: Types.GetParticipantBiometricsParams,
  ): Promise<Types.GetParticipantBiometricsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}/profile_biometrics`,
    )
    return this.processResponse(response)
  }
  getParticipantConditions = async (
    params: Types.GetParticipantConditionsParams,
  ): Promise<Types.GetParticipantConditionsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}/conditions`,
    )
    return this.processResponse(response)
  }
  getParticipantProgress = async (
    params: Types.GetParticipantProgressParams,
  ): Promise<Types.GetParticipantProgressResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}/progress`,
    )
    return this.processResponse(response)
  }
  getParticipantMeta = async (
    params: Types.GetParticipantMetaParams,
  ): Promise<Types.GetParticipantMetaResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/participants/${params.participantID}/meta`,
    )
    return this.processResponse(response)
  }

  getFeedPosts = async (
    params: Types.GetFeedPostsParams,
  ): Promise<Types.GetFeedPostsResult> => {
    const response = await this.apisauce.get(
      `admin/${this.personId}/user_posts`,
      params,
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetPersonDetails = async (
    params: Types.AdminGetPersonDetailsParams,
  ): Promise<Types.AdminGetPersonDetailsResult> => {
    const response = await this.apisauce.get(
      `admin/${this.personId}/person/${params.personId}`,
      {},
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  getVideoChatEvents = async (
    params: Types.GetVideoChatEventsParams,
  ): Promise<Types.GetVideoChatEventsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/video_chat_event`,
    )
    return this.processResponse(response, false)
  }

  getVideoChatToken = async (
    params: Types.GetVideoChatTokenParams,
  ): Promise<Types.GetVideoChatTokenResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/cohorts/${params.cohortId}/video_chat_event/${params.eventId}/initiate`,
    )
    return this.processResponse(response, false)
  }

  adminMutePost = async (
    params: Types.AdminMutePostParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/mute/user_post/${params.postId}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminCreatePost = async (
    params: Types.AdminCreatePostParams,
  ): Promise<Types.AdminCreatePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/user_posts`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminSearchUser = async (
    params: Types.AdminSearchUserParams,
  ): Promise<Types.AdminSearchUserResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/search_person`,
      { query: params.query },
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminSearchPost = async (
    params: Types.AdminSearchPostParams,
  ): Promise<Types.AdminSearchPostResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/search_user_post`,
      { query: params.query },
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminBanUser = async (
    params: Types.AdminBanUserParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/person/${params.userId}/mute_user`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminUnMutePost = async (
    params: Types.AdminUnMutePostParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/unmute/user_post/${params.postId}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminUnBanUser = async (
    params: Types.AdminUnBanUserParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/person/${params.userId}/unmute_user`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteUser = async (
    params: Types.AdminUnBanUserParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/person/${params.userId}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminUnsubscribeEmail = async (
    params: Types.AdminUnsubscribeEmailParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/campaigns/unsubscribe`,
      { email: params.email },
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetUserInvitations = async (
    params: Types.AdminGetUserInvitationsParams,
  ): Promise<Types.AdminGetUserInvitationsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/user_invitation`,
      { email: params.email },
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminDeleteUserInvitations = async (
    params: Types.AdminGetUserInvitationsParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/user_invitation?email=${params.email}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetAllPrograms = async (): Promise<Types.AdminGetAllProgramsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/programs`,
      {},
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminChangePersonProgram = async (
    params: Types.AdminChangePersonProgramParams,
  ): Promise<Types.AdminMutePostResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/person/${params.personId}/programs/${params.programId}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetPersonVerificationCodes = async (
    params: Types.AdminGetPersonVerificationCodesParams,
  ): Promise<Types.AdminGetPersonVerificationCodesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/verification_codes/person/${params.personID}`,
      {},
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminUpdatePerson = async ({
    personID,
    ...params
  }: Types.AdminUpdatePersonParams): Promise<Types.AdminUpdatePersonResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/person/${personID}`,
      params,
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminUpdatePersonProfile = async ({
    personID,
    profileID,
    ...params
  }: Types.AdminUpdatePersonProfileParams): Promise<Types.AdminUpdatePersonProfileResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/person/${personID}/profile`,
      { ...params, id: profileID },
      {
        //custom base url for mindset endpoint
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetMslEvents = async (
    params: Types.AdminGetMslEventsParams,
  ): Promise<Types.AdminGetMslEventsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/msl_video_events`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminSearchMslEvents = async (
    params: Types.AdminSearchMslEventsParams,
  ): Promise<Types.AdminSearchMslEventsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/msl_video_events/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminCreateMslEvent = async (
    params: Types.AdminCreateMslEventParams,
  ): Promise<Types.AdminCreateMslEventResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/msl_video_events`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditMslEvent = async (
    params: Types.AdminEditMslEventParams,
  ): Promise<Types.AdminEditMslEventResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/msl_video_events`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminPublishMslEvent = async (
    params: Types.AdminPublishMslEventParams,
  ): Promise<Types.AdminPublishMslEventResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/msl_video_events/${params.eventId}/publish`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminUnPublishMslEvent = async (
    params: Types.AdminPublishMslEventParams,
  ): Promise<Types.AdminPublishMslEventResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/msl_video_events/${params.eventId}/un_publish`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteMslEvent = async (
    params: Types.AdminDeleteMslEventParams,
  ): Promise<Types.AdminDeleteMslEventResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/msl_video_events/${params.eventId}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  getEventStreamingToken = async (
    params: Types.GetEventStreamingTokenParams,
  ): Promise<Types.GetEventStreamingTokenResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/msl_video_events/${params.eventId}/start`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEndMslEvent = async (
    params: Types.AdminEndMslEventParams,
  ): Promise<Types.AdminEndMslEventResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/msl_video_events/${params.eventId}/end`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetMindsetTrainers = async (): Promise<Types.AdminGetMindsetTrainers> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/mindset_trainers`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetEventTopics = async (): Promise<Types.AdminGetEventTopics> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/msl_video_event_topics`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTrainer = async (
    params: Types.AdminCreateTrainerParams,
  ): Promise<Types.AdminCreateTrainerResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/mindset_trainers`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminUpdateTrainer = async (
    params: Types.AdminUpdateTrainerParams,
  ): Promise<Types.AdminUpdateTrainerResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/mindset_trainers`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteTrainer = async (
    params: Types.AdminDeleteTrainerParams,
  ): Promise<Types.AdminDeleteTrainerResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/mindset_trainers/${params.id}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateEventTopic = async (
    params: Types.AdminCreateEventTopicParams,
  ): Promise<Types.AdminCreateEventTopicResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/msl_video_event_topics`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetInvitations = async (
    params: Types.AdminGetInvitationsParams,
  ): Promise<Types.AdminGetInvitationsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/invitations`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetNonDppGroups = async (): Promise<Types.AdminGetNonDppGroups> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/non_dpp_groups`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminSendInvitations = async (
    params: Types.AdminSendInvitationsParams,
  ): Promise<Types.AdminSearchPostResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/send_invitations`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetEntitlements = async (): Promise<Types.AdminGetEntitlements> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/entitlements`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetPrograms = async (): Promise<Types.AdminGetPrograms> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/programs`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminCreateGroup = async (
    params: Types.AdminCreateGroupParams,
  ): Promise<Types.AdminCreateGroupResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/groups`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetCoaches = async (): Promise<Types.AdminGetCoaches> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/coaches`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetTags = async (): Promise<Types.AdminGetTags> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/tags`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddTagToEvent = async (
    params: Types.AdminAddTagToEventParams,
  ): Promise<Types.AdminAddTagToEventResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/msl_video_events/${params.eventID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminRemoveTagToEvent = async (
    params: Types.AdminAddTagToEventParams,
  ): Promise<Types.AdminAddTagToEventResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/msl_video_events/${params.eventID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetSeries = async (): Promise<Types.AdminGetSeries> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/train/series`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateSeries = async (
    params: Types.AdminCreateSeriesParams,
  ): Promise<Types.AdminCreateSeriesResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/series`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditSeries = async (
    params: Types.AdminEditSeriesParams,
  ): Promise<Types.AdminEditSeriesResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/train/series/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminUpdateSeriesEvents = async (
    params: Types.AdminUpdateSeriesEventsParams,
  ): Promise<Types.AdminUpdateSeriesEventsResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/train/series/${params.id}/events`,
      params.events,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteSeries = async (
    params: Types.AdminDeleteSeriesParams,
  ): Promise<Types.AdminDeleteSeriesResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/series/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddTagToSeries = async (
    params: Types.AdminAddTagToSeriesParams,
  ): Promise<Types.AdminAddTagToSeriesResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/series/${params.seriesID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminRemoveTagToSeries = async (
    params: Types.AdminAddTagToSeriesParams,
  ): Promise<Types.AdminAddTagToSeriesResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/series/${params.seriesID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetCategories = async (): Promise<Types.AdminGetCategories> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/train/category`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateCategory = async (
    params: Types.AdminCreateCategoryParams,
  ): Promise<Types.AdminCreateCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/category`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditCategory = async (
    params: Types.AdminEditCategoryParams,
  ): Promise<Types.AdminEditCategoryResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/train/category/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteCategory = async (
    params: Types.AdminDeleteCategoryParams,
  ): Promise<Types.AdminDeleteCategoryResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/category/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddTagToCategory = async (
    params: Types.AdminAddTagToCategoryParams,
  ): Promise<Types.AdminAddTagToCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/category/${params.categoryID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminRemoveTagToCategory = async (
    params: Types.AdminAddTagToCategoryParams,
  ): Promise<Types.AdminAddTagToCategoryResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/category/${params.categoryID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddEpisodeToCategory = async (
    params: Types.AdminAddEpisodeToCategoryParams,
  ): Promise<Types.AdminAddEpisodeToCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/category/${params.categoryID}/events/${params.episodeID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminRemoveEpisodeToCategory = async (
    params: Types.AdminAddEpisodeToCategoryParams,
  ): Promise<Types.AdminAddEpisodeToCategoryResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/category/${params.categoryID}/events/${params.episodeID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetThemes = async (): Promise<Types.AdminGetThemesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/train/themes`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTheme = async (
    params: Types.AdminCreateThemeParams,
  ): Promise<Types.AdminCreateThemeResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/themes`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditTheme = async (
    params: Types.AdminEditThemeParams,
  ): Promise<Types.AdminEditThemeResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/train/themes/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteTheme = async (
    params: Types.AdminDeleteThemeParams,
  ): Promise<Types.AdminDeleteThemeResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/themes/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddTagToTheme = async (
    params: Types.AdminAddTagToThemeParams,
  ): Promise<Types.AdminAddTagToThemeResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/train/themes/${params.themeID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminRemoveTagToTheme = async (
    params: Types.AdminAddTagToThemeParams,
  ): Promise<Types.AdminAddTagToThemeResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/train/themes/${params.themeID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminUpdateThemeEvents = async (
    params: Types.AdminUpdateThemeEventsParams,
  ): Promise<Types.AdminUpdateThemeEventsResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/train/themes/${params.id}/events`,
      params.events,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetNudges = async (): Promise<Types.AdminGetNudgesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/notifications`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetNudgeTypes = async (): Promise<Types.AdminGetNudgeTypesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/notifications/types`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateNudge = async (
    params: Types.AdminCreateNudgeParams,
  ): Promise<Types.AdminCreateNudgeResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/notifications`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditNudge = async (
    params: Types.AdminEditNudgeParams,
  ): Promise<Types.AdminEditNudgeResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/notifications`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTagTypes = async (): Promise<Types.AdminGetTagTypesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/tag_types`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTag = async (
    params: Types.AdminCreateTagParams,
  ): Promise<Types.AdminCreateTagResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/tags`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditTag = async (
    params: Types.AdminEditTagParams,
  ): Promise<Types.AdminEditTagResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/tags`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteTag = async (
    params: Types.AdminDeleteTagParams,
  ): Promise<Types.AdminDeleteTagResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/tags/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetTiles = async (): Promise<Types.AdminGetTilesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/whats_next_tiles`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetTileTypes = async (): Promise<Types.AdminGetTileTypesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/whats_next_tiles/types`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTile = async (
    params: Types.AdminCreateTileParams,
  ): Promise<Types.AdminCreateTileResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/whats_next_tiles`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditTile = async (
    params: Types.AdminEditTileParams,
  ): Promise<Types.AdminEditTileResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/whats_next_tiles`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetRakCards = async (): Promise<Types.AdminGetRakCardsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/rak_cards`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateRakCard = async (
    params: Types.AdminCreateRakCardParams,
  ): Promise<Types.AdminCreateRakCardResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/rak_cards`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditRakCard = async (
    params: Types.AdminEditRakCardParams,
  ): Promise<Types.AdminEditRakCardResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/rak_cards`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  getParticipantNotes = async ({
    participantId,
  }): Promise<Types.GetParticipantNotesResult> => {
    const response = await this.apisauce.get(
      `person/${this.personId}/participants/${participantId}/notes`,
      {},
    )
    return this.processResponse(response)
  }

  createParticipantNote = async ({
    groupID,
    ...params
  }: Types.CreateParticipantNoteParams): Promise<Types.CreateParticipantNoteResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/groups/${groupID}/notes`,
      params,
    )
    return this.processResponse(response)
  }
  updateParticipantNote = async ({
    id,
    groupID,
    ...params
  }: Types.UpdateParticipantNoteParams): Promise<Types.UpdateParticipantNoteResult> => {
    const response = await this.apisauce.put(
      `/person/${this.personId}/groups/${groupID}/notes/${id}`,
      params,
    )
    return this.processResponse(response)
  }
  deleteParticipantNote = async ({
    id,
    groupId,
  }: Types.DeleteParticipantNoteParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/groups/${groupId}/notes/${id}`,
    )
    return this.processResponse(response)
  }
  getPilotCoachSavedChats = async (): Promise<Types.GetPilotCoachSavedChatsResult> => {
    const response = await this.apisauce.get(
      `/person/${this.personId}/chats/saved_chats`,
      {},
    )
    return this.processResponse(response)
  }
  pilotCoachSaveChat = async (
    params: Types.PilotCoachSaveChatParams,
  ): Promise<Types.PilotCoachSaveChatResult> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/chats/save_chat/${params.chatID}`,
      params,
    )
    return this.processResponse(response)
  }
  pilotCoachDeleteChat = async (
    params: Types.PilotCoachDeleteChatParams,
  ): Promise<Types.PilotCoachDeleteChatResult> => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/chats/save_chat/${params.chatID}`,
      params,
    )
    return this.processResponse(response)
  }
  adminGetDidYouKnowTiles = async (): Promise<Types.AdminGetDidYouKnowTilesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/did_you_know`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminCreateDidYouKnowTile = async (
    params: Types.AdminCreateDidYouKnowTileParams,
  ): Promise<Types.AdminCreateDidYouKnowTileResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/did_you_know`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditDidYouKnowTile = async (
    params: Types.AdminEditDidYouKnowTileParams,
  ): Promise<Types.AdminEditDidYouKnowTileResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/did_you_know`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetVerificationCodes = async ({
    email,
  }: Types.AdminGetVerificationCodesParams): Promise<Types.AdminGetVerificationCodesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/verification_codes/email/${email}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminGetRecipes = async (
    params: any,
  ): Promise<Types.AdminGetRecipesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/recipes?page=${params.page}&pageSize=${params.pageSize}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminCreateRecipe = async (
    params: Types.AdminCreateRecipeParams,
  ): Promise<Types.AdminCreateRecipeResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/recipe`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditRecipe = async (
    params: Types.AdminEditRecipeParams,
  ): Promise<Types.AdminEditRecipeResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/recipe/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteRecipe = async (
    params: Types.AdminDeleteRecipeParams,
  ): Promise<Types.AdminDeleteRecipeResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/recipe/${params.recipeId}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetUploadContentURL = async (params: {}): Promise<any> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/content/upload/token`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )

    return this.processResponse(response)
  }

  adminUploadContent = async (
    params: { file: Blob; url: string },
    setProgress: ({ loaded, total }: { loaded: number; total: number }) => void,
  ) => {
    try {
      const headers = {
        "Content-Type": params.file.type,
        "x-amz-acl": "public-read",
      }
      await axios.put(params.url, params.file, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total ?? params.file.size

          setProgress({ loaded, total })
        },
      })

      const contentURL = params.url.split("?")[0]

      return this.processResponse(
        { kind: "ok", data: { url: contentURL } },
        true,
      )
    } catch (error) {
      this.processResponse({ kind: "error", data: { reason: error } }, true)
    }
  }

  adminUpdateUploadStatus = async (params: {
    fileID: string
    status: string
  }): Promise<string> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/content/upload/status`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )

    return this.processResponse(response)
  }
  adminGetWhatVideos = async (): Promise<Types.AdminGetWhatVideosResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/checkin/category_videos`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetWhatVideoCategories = async (): Promise<Types.AdminGetWhatVideoCategoriesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/checkin/category`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetPublishedVideos = async (): Promise<Types.AdminGetPublishedVideosResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/msl_video_events/published`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminCreateWhatVideo = async (
    params: Types.AdminCreateWhatVideoParams,
  ): Promise<Types.AdminCreateWhatVideoResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/checkin/category_videos`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditWhatVideo = async (
    params: Types.AdminEditWhatVideoParams,
  ): Promise<Types.AdminEditWhatVideoResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/checkin/category_videos`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriIdeas = async (params: {}): Promise<Types.AdminGetTriIdeasResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/idea`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetTriIdeasByQuery = async (params: {}): Promise<Types.AdminGetTriIdeasResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/idea/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminCreateTriIdea = async (
    params: Types.AdminCreateTriIdeaParams,
  ): Promise<Types.AdminCreateTriIdeaResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/idea`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminEditTriIdea = async (
    params: Types.AdminEditTriIdeaParams,
  ): Promise<Types.AdminEditTriIdeaResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/idea/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriIdea = async (
    params: Types.AdminDeleteTriIdeaParams,
  ): Promise<Types.AdminDeleteTriIdeaResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/idea/${params.ideaID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriIdeaTypes = async (): Promise<Types.AdminGetTriIdeasTypesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/idea/types`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddTagToTriIdea = async (
    params: Types.AdminAddTagToTriIdeaParams,
  ): Promise<Types.AdminAddTagToTriIdeaResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/idea/${params.ideaID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriIdeaTag = async (
    params: Types.AdminDeleteTriIdeaTagParams,
  ): Promise<Types.AdminDeleteTriIdeaTagResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/idea/${params.ideaID}/tags/${params.tagID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddRecipeToTriIdea = async (
    params: Types.AdminAddRecipeToTriIdeaParams,
  ): Promise<Types.AdminAddRecipeToTriIdeaResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/idea/${params.ideaID}/recipes/${params.recipeID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriIdeaRecipe = async (
    params: Types.AdminDeleteTriIdeaRecipeParams,
  ): Promise<Types.AdminDeleteTriIdeaRecipeResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/idea/${params.ideaID}/recipes/${params.recipeID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddProductToTriIdea = async (
    params: Types.AdminAddProductToTriIdeaParams,
  ): Promise<Types.AdminAddProductToTriIdeaResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/idea/${params.ideaID}/products/${params.productID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriIdeaProduct = async (
    params: Types.AdminDeleteTriIdeaProductParams,
  ): Promise<Types.AdminDeleteTriIdeaProductResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/idea/${params.ideaID}/products/${params.productsID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriIdeaRecipes = async (params: {}): Promise<Types.AdminGetTriIdeaRecipesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/recipes/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetTriIdeaProducts = async (params: {}): Promise<Types.AdminGetTriIdeaProductsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/products/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetTriProducts = async (params: {}): Promise<Types.AdminGetTriProductsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/products`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetTriBehaviors = async (params: {}): Promise<Types.AdminGetTriBehaviorsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/behaviors`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetTriBehaviorsSearch = async (params: {}): Promise<Types.AdminGetTriBehaviorsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/behaviors/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetSingleTriProduct = async (
    params: Types.AdminGetSingleTriProductParams,
  ): Promise<Types.AdminGetSingleTriProductResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/product/${params.productID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetSingleTriBehavior = async (
    params: Types.AdminGetSingleTriBehaviorParams,
  ): Promise<Types.AdminGetSingleTriBehaviorResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/behaviors/${params.behaviorID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminCreateTriProduct = async (
    params: Types.AdminCreateTriProductParams,
  ): Promise<Types.AdminCreateTriProductResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/product`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTriBehavior = async (
    params: Types.AdminCreateTriBehaviorParams,
  ): Promise<Types.AdminCreateTriBehaviorResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/behaviors`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminEditTriProduct = async (
    params: Types.AdminEditTriProductParams,
  ): Promise<Types.AdminEditTriProductResult> => {
    const response = await this.apisauce.put(
      `/admin/${this.personId}/product/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditTriBehavior = async (
    params: Types.AdminEditTriBehaviorParams,
  ): Promise<Types.AdminEditTriBehaviorResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/behaviors`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriProduct = async (
    params: Types.AdminDeleteTriProductParams,
  ): Promise<Types.AdminDeleteTriProductResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/product/${params.productID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteTriBehavior = async (
    params: Types.AdminDeleteTriBehaviorParams,
  ): Promise<Types.AdminDeleteTriBehaviorResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/behaviors/${params.behaviorID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddTagToTriBehavior = async (
    params: Types.AdminAddTagToTriBehaviorParams,
  ): Promise<Types.AdminAddTagToTriBehaviorResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/behaviors/${params.behaviorID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriBehaviorTag = async (
    params: Types.AdminDeleteTriBehaviorTagParams,
  ): Promise<Types.AdminDeleteTriBehaviorTagResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/behaviors/${params.behaviorID}/tags/${params.tagID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddIdeaToTriBehavior = async (
    params: Types.AdminAddIdeaToTriBehaviorParams,
  ): Promise<Types.AdminAddIdeaToTriBehaviorResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/behaviors/${params.behaviorID}/ideas/${params.ideaID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriBehaviorIdea = async (
    params: Types.AdminDeleteTriBehaviorIdeaParams,
  ): Promise<Types.AdminDeleteTriBehaviorIdeaResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/behaviors/${params.behaviorID}/ideas/${params.ideaID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriCategories = async (params: {}): Promise<Types.AdminGetTriCategoriesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/categories`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminGetSingleTriCategory = async (
    params: Types.AdminGetSingleTriCategoryParams,
  ): Promise<Types.AdminGetSingleTriCategoryResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/categories/${params.categoryID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminSearchTriCategories = async (
    params: Types.AdminSearchTriCategoriesParams,
  ): Promise<Types.AdminSearchTriCategoriesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/categories/search`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }

  adminCreateTriCategory = async (
    params: Types.AdminCreateTriCategoryParams,
  ): Promise<Types.AdminCreateTriCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/categories`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminEditTriCategory = async (
    params: Types.AdminEditTriCategoryParams,
  ): Promise<Types.AdminEditTriCategoryResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/categories`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriCategory = async (
    params: Types.AdminDeleteTriCategoryParams,
  ): Promise<Types.AdminDeleteTriCategoryResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/categories/${params.categoryID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddTagToTriCategory = async (
    params: Types.AdminAddTagToTriCategoryParams,
  ): Promise<Types.AdminAddTagToTriCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/categories/${params.categoryID}/tags/${params.tagID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriCategoryTag = async (
    params: Types.AdminDeleteTriCategoryTagParams,
  ): Promise<Types.AdminDeleteTriCategoryTagResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/categories/${params.categoryID}/tags/${params.tagID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddIdeaToTriCategory = async (
    params: Types.AdminAddIdeaToTriCategoryParams,
  ): Promise<Types.AdminAddIdeaToTriCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/categories/${params.categoryID}/ideas/${params.ideaID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriCategoryIdea = async (
    params: Types.AdminDeleteTriCategoryIdeaParams,
  ): Promise<Types.AdminDeleteTriCategoryIdeaResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/categories/${params.categoryID}/ideas/${params.ideaID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddBehaviorToTriCategory = async (
    params: Types.AdminAddBehaviorToTriCategoryParams,
  ): Promise<Types.AdminAddIBehaviorToTriCategoryResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/categories/${params.categoryID}/behaviors/${params.behaviorID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminDeleteTriCategoryBehavior = async (
    params: Types.AdminDeleteTriCategoryBehaviorParams,
  ): Promise<Types.AdminDeleteTriCategoryBehaviorResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/categories/${params.categoryID}/behaviors/${params.behaviorID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriPrograms = async (): Promise<Types.AdminGetTriProgramsResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/programs`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response, true)
  }
  adminGetTriModules = async (): Promise<Types.AdminGetTriModulesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/modules`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )

    return this.processResponse(response)
  }

  adminEditTriProgram = async (
    params: Types.AdminEditTriProgramParams,
  ): Promise<Types.AdminEditTriProgramResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/programs/${params.id}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminCreateTriModule = async (
    params: Types.AdminCreateTriModuleParams,
  ): Promise<Types.AdminCreateTriModuleResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/modules`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminGetTriProgramModules = async (
    params: Types.AdminGetTriProgramModulesParams,
  ): Promise<Types.AdminGetTriProgramModulesResult> => {
    const response = await this.apisauce.get(
      `/admin/${this.personId}/programs/${params.programID}/modules`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminEditTriModule = async (
    params: Types.AdminEditTriModuleParams,
  ): Promise<Types.AdminEditTriModuleResult> => {
    const response = await this.apisauce.patch(
      `/admin/${this.personId}/modules`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteTriModule = async (
    params: Types.AdminDeleteTriModuleParams,
  ): Promise<Types.AdminDeleteTriModuleResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/modules/${params.moduleID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminAddCategoryToModule = async (
    params: Types.AdminAddCategoryToModuleParams,
  ): Promise<Types.AdminAddCategoryToModuleResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/modules/${params.moduleID}/categories/${params.categoryID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  adminAddTriProgramsModule = async (
    params: Types.AdminAddtTriProgramModulesParams,
  ): Promise<Types.AdminAddTriProgramModulesResult> => {
    const response = await this.apisauce.post(
      `/admin/${this.personId}/programs/${params.programID}/modules/${params.moduleID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteModuleFromProgram = async (
    params: Types.AdminDeleteModuleFromProgramParams,
  ): Promise<Types.AdminDeleteModuleFromProgramResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/programs/${params.programID}/modules/${params.moduleID}`,
      {},
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }
  adminDeleteCategoryFromModule = async (
    params: Types.AdminDeleteCategoryFromModuleParams,
  ): Promise<Types.AdminDeleteCategoryFromModuleResult> => {
    const response = await this.apisauce.delete(
      `/admin/${this.personId}/modules/${params.moduleID}/categories/${params.categoryID}`,
      params,
      {
        baseURL: `${this.config.url}`.replace("/pro", ""),
      },
    )
    return this.processResponse(response)
  }

  updateCoachStatus = async (params: { online: boolean }) => {
    const response = await this.apisauce.put(
      `person/${this.personId}/coach/status`,
      params,
    )
    return this.processResponse(response, true)
  }

  updateCoachDailyNote = async (params: { note: string }) => {
    const response = await this.apisauce.patch(
      `person/${this.personId}/coaches/update_coach_note`,
      params,
    )
    return this.processResponse(response, true)
  }

  createFeedPost = async (
    params: Types.AdminCreateFeedPostParams,
  ): Promise<any> => {
    const response = await this.apisauce.post(
      `/person/${this.personId}/group/${params.groupID}/affinity_group_chat`,
      params,
    )
    return this.processResponse(response)
  }

  deleteFeedPost = async (params: Types.DeleteFeedPostParams) => {
    const response = await this.apisauce.delete(
      `/person/${this.personId}/chats/${params.postID}`,
    )
    return this.processResponse(response)
  }
}
