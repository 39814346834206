import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

export const PostModel = types
  .model("Post")
  .props({
    id: types.identifier,
    personID: types.maybeNull(types.string),
    personName: types.maybeNull(types.string),
    avatarImageURL: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    isAnonymous: types.maybeNull(types.boolean),
    postType: types.optional(
      types.union(types.literal("gratitude"), types.literal("intention")),
      "gratitude",
    ),
    favorited: types.maybeNull(types.boolean),
    cardSent: types.maybeNull(types.boolean),
    isMuted: types.maybeNull(types.boolean),
    createdAt: types.optional(customTypes.iso8601, moment().toDate()),
    updatedAt: types.optional(customTypes.iso8601, moment().toDate()),
    userPostAllowed: types.maybeNull(types.boolean),
    muteReason: types.maybeNull(types.string),
    wasSeeded: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    toggleMuted() {
      self.isMuted = !self.isMuted
    },
    toggleUserBanned() {
      self.userPostAllowed = !self.userPostAllowed
    },
    setMuteReason(value: string) {
      self.muteReason = value
    },
    setPersonID(value: string) {
      self.personID = value
    },
    setMessage(value: string) {
      self.message = value
    },
    setIsAnonymous(value: boolean) {
      self.isAnonymous = value
    },
    setPostType(value: "gratitude" | "intention") {
      self.postType = value
    },
    setCreatedAt(value: Date | null) {
      self.createdAt = value
    },
    reset() {
      self.id = "-1"
      self.personID = ""
      self.message = ""
      self.createdAt = null
      self.isAnonymous = false
      self.postType = "gratitude"
    },
  }))
  .actions((self) => ({
    usePostAsTemplate(post) {
      const { personID, message, isAnonymous, postType, createdAt } = post
      self.personID = personID
      self.message = message
      self.isAnonymous = isAnonymous
      self.postType = postType
      self.createdAt = createdAt
    },
  }))
  .views((self) => ({
    get createPostErrors() {
      return validate(CREATE_NEW_POST_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
    get formattedPostDate() {
      return moment(self.createdAt).format("M/D/YY")
    },
    get formattedPostTime() {
      return moment(self.createdAt).format("h:mma")
    },
    get isPersonBanned() {
      return !self.userPostAllowed
    },
  }))

const CREATE_NEW_POST_RULES: ValidationRules = {
  personID: {
    presence: { allowEmpty: false, message: "required" },
  },
  message: {
    presence: { allowEmpty: false, message: "required" },
  },
  createdAt: {
    presence: { allowEmpty: false, message: "required" },
  },
  postType: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface Post extends Instance<typeof PostModel> {}
export interface PostSnapshot extends SnapshotOut<typeof PostModel> {}
