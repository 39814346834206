import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTriNestedProductForm } from "./useTriNestedProductForm"
import { TriProduct } from "../../models/tri-product"

interface CreateTriNestedProductModalProps {
  open: boolean
}

const CreateTriNestedProductModal = observer(
  ({ open }: CreateTriNestedProductModalProps) => {
    const {
      nestedTriProduct,
      loading,
      submitted,
      onCancel,
      onSubmit,
      triProductFormConfig,
    } = useTriNestedProductForm({ isModalOpen: open })

    const { createTriProductErrors: errors } = nestedTriProduct

    const configFormFiltered = triProductFormConfig.filter(
      ({ fieldName }) => fieldName,
    )

    return (
      <FormModal
        open={open}
        modalTitle={"Edit Product"}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={"Save"}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<TriProduct>
            submitted={submitted}
            values={nestedTriProduct}
            errors={errors}
            isEditMode={true}
            formConfig={configFormFiltered}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateTriNestedProductModal }
