import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTriProgramForm } from "./useTriProgramForm"
import { TriProgram } from "../../models/tri-program"

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new Program",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit Program", buttonLabel: "Save" },
}

interface CreateTriHowToModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateTriProgramModal = observer(
  ({ mode, open, closeModal }: CreateTriHowToModalProps) => {
    const {
      newTriProgram,
      loading,
      submitted,
      onCancel,
      onSubmit,
      triProgramFormConfig,
    } = useTriProgramForm({ mode, closeModal, isModalOpen: open })

    const { createTriProgramErrors: errors } = newTriProgram

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<TriProgram>
            submitted={submitted}
            values={newTriProgram}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT}
            formConfig={triProgramFormConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateTriProgramModal }
