import { useState } from "react"
import {
  Stack,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core"

import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { ReactComponent as DeleteSessionIcon } from "../../assets/images/delete_session_icon.svg"

interface TriCategoryActionsProps {
  triCategoryID: string
}

const TriCategoryActions = ({ triCategoryID }: TriCategoryActionsProps) => {
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const { triCategoriesPagination } = adminMslEventStoreModel
  const { apiAdminDeleteTriCategory } = triCategoriesPagination

  const handleDeleteTriCategory = async () => {
    try {
      toggleDeleteDialog(false)
      setLoadingDelete(true)
      await apiAdminDeleteTriCategory(triCategoryID)
      setSnackbarSeverity("success")
      setSnackbarText("Successfully deleted category !")
      setShowSnackbar(true)
      setLoadingDelete(false)
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
      setLoadingDelete(false)
    }
  }

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      spacing={1}>
      <Dialog
        open={isDeleteDialogOpen}
        aria-labelledby="alert-delete-tri-category-title">
        <DialogTitle id="alert-delete-tri-category-title">
          Are you sure you want to delete this category?
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => toggleDeleteDialog(false)}>Cancel</Button>

          <Button autoFocus onClick={handleDeleteTriCategory}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        disabled={loadingDelete}
        onClick={() => toggleDeleteDialog(true)}>
        {loadingDelete ? (
          <CircularProgress color="secondary" size={24} />
        ) : (
          <DeleteSessionIcon width={18} height={18} />
        )}
      </IconButton>
    </Stack>
  )
}

export { TriCategoryActions }
