import React from "react"
import { Theme, Stack } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { switcher } from "rambdax"

import { ChatMessage, ChatType } from "../../models/chat-message"
import { ChatBubbleLog } from "./chat-bubble-log"
import { typography } from "../../services/theme/typography"
import { DefaultChatBubble } from "./default-bubble-component"
import { TrainContent } from "./chat-bubble-extra-content"
import { Flag } from "./flag"
import { CustomTooltip } from "../admin-msl-events-list/short-text"

interface StyleProps {
  isMessageFromCoach: boolean
  isFlaggedMessage: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(0, 0, 3, 0),
      display: "flex",
      flexDirection: (props) => {
        if (props.isMessageFromCoach) {
          return "row-reverse"
        }
        return "row"
      },
      alignItems: "flex-end",
    },
    messageContainer: {
      width: "100%",
      alignItems: (props) => {
        if (props.isMessageFromCoach) {
          return "flex-end"
        }
        return "flex-start"
      },
    },
    dateText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(5),
      fontSize: theme.spacing(2),
      color: "#888C96",
      ...typography.circularXXRegular,
    },
    divider: {
      width: 170,
      height: 1,
      margin: theme.spacing(0, 1.5),
      background: "#24645C1A",
    },
    "@keyframes fadeInUp": {
      from: {
        transform: "translate3d(0,40px,0)",
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: 1,
      },
    },
    flagMessageContainer: {
      display: "flex",
      alignItems: "center",
    },
    coachAvatar: {
      alignSelf: "end",
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
      marginLeft: theme.spacing(2),
    },
  }),
)

interface ChatBubbleProps {
  message: ChatMessage
  isMessageFromCurrentPerson: boolean
  isMessageFromCoach: boolean
  showAuthorName?: boolean
  onMessageDelete: () => void
  isFlaggedMessage?: boolean
}

export const ChatBubble = React.memo(
  ({
    message,
    isMessageFromCurrentPerson,
    isMessageFromCoach,
    showAuthorName,
    onMessageDelete,
  }: ChatBubbleProps) => {
    const classes = useStyles({
      isMessageFromCoach,
      isFlaggedMessage: message.isSavedChat,
    })

    const bubble = switcher<any>(message.type)
      .is(
        ChatType.Weight,
        <ChatBubbleLog message={message} showAuthorName={showAuthorName} />,
      )
      .is(
        ChatType.Exercise,
        <ChatBubbleLog message={message} showAuthorName={showAuthorName} />,
      )
      .is(
        ChatType.Gratitude,
        <ChatBubbleLog message={message} showAuthorName={showAuthorName} />,
      )
      .is(
        ChatType.Idea,
        <ChatBubbleLog message={message} showAuthorName={showAuthorName} />,
      )
      .is(
        ChatType.Train,
        <DefaultChatBubble
          message={message}
          isMessageFromCurrentPerson={isMessageFromCurrentPerson}
          isMessageFromCoach={isMessageFromCoach}
          showAuthorName={showAuthorName}
          onMessageDelete={onMessageDelete}
          isFlaggedMessage={message.isSavedChat}
          CustomContent={<TrainContent message={message} />}
        />,
      )
      .default(
        <DefaultChatBubble
          message={message}
          isMessageFromCurrentPerson={isMessageFromCurrentPerson}
          isMessageFromCoach={isMessageFromCoach}
          showAuthorName={showAuthorName}
          onMessageDelete={onMessageDelete}
          isFlaggedMessage={message.isSavedChat}
        />,
      )

    return (
      <div className={classes.wrapper}>
        <Stack className={classes.messageContainer}>
          {Boolean(message.dateLabel) && (
            <div className={classes.dateText}>
              <div className={classes.divider} />
              {message.dateLabel}
              <div className={classes.divider} />
            </div>
          )}
          <div className={classes.flagMessageContainer}>
            {!isMessageFromCurrentPerson && <Flag message={message} />}
            {bubble}
            {isMessageFromCoach && (
              <CustomTooltip title={message.author.fullName}>
                <img
                  src={message.author.avatarURL}
                  className={classes.coachAvatar}
                  alt="Person Avatar"
                />
              </CustomTooltip>
            )}
          </div>
        </Stack>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const areEqual =
      prevProps.isMessageFromCurrentPerson ===
        nextProps.isMessageFromCurrentPerson &&
      prevProps.isMessageFromCoach === nextProps.isMessageFromCoach &&
      prevProps.message?.log?.verified === nextProps.message?.log?.verified &&
      prevProps.message.message === nextProps.message.message &&
      prevProps.message.meta === nextProps.message.meta &&
      prevProps.message.isSavedChat === nextProps.message.isSavedChat &&
      prevProps.message.dateLabel === nextProps.message.dateLabel

    return areEqual
  },
)
