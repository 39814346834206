import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ShortText } from "../admin-msl-events-list/short-text"
import { useStores } from "../../models/root-store"
import { TriProgram } from "../../models/tri-program"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTriProgramModal?: (event: TriProgram) => void
  triProgramID?: string
}
const CellComponent = ({
  openEditTriProgramModal,
  triProgramID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { triPrograms } = adminMslEventStoreModel
  const { programs } = triPrograms
  const triProgram = programs.find((program) => program.id === triProgramID)

  switch (props.field) {
    case "createdAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "updatedAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "actions":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditTriProgramModal(triProgram)}>
            Edit
          </Button>
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
