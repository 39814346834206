import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { MslEventModel, MslEvent } from "./msl-event"
import {
  CREATED_AT,
  NAME,
  ASCENDING,
  DESCENDING,
  ORDER_BY_TYPE,
  PUBLISH_START_TIME,
} from "./filter-types"

export const PAGE = 1
export const PAGE_SIZE = 20

/**
 * Represents a pagination model.
 */
export const MslEventsPaginationModel = types
  .model("MslEventsPagination")
  .props({
    loading: types.optional(types.boolean, false),
    page: types.optional(types.number, PAGE),
    pageSize: types.optional(types.number, PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
    next: types.maybeNull(types.number),
    //sort and filters flags
    sortBy: types.optional(
      types.union(
        types.literal(CREATED_AT),
        types.literal(PUBLISH_START_TIME),
        types.literal(NAME),
      ),
      CREATED_AT,
    ),
    orderBy: types.optional(
      types.union(types.literal(ASCENDING), types.literal(DESCENDING)),
      DESCENDING,
    ),
    filtered: types.maybeNull(types.boolean),
    showSearchResults: types.maybeNull(types.boolean),
    mslEvents: types.optional(types.array(MslEventModel), []),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },
    setPage(value: number) {
      self.page = value
    },
    setPageSize(value: number) {
      self.pageSize = value
    },

    setPagination(value: { totalCount: number; next: number | null }) {
      self.totalCount = value.totalCount
      self.next = value.next
    },
    setSortBy(value: string) {
      if (value === NAME) {
        self.sortBy = NAME
      }

      if (value === "formattedPublishStartTime") {
        self.sortBy = PUBLISH_START_TIME
      }

      if (value === CREATED_AT) {
        self.sortBy = CREATED_AT
      }
    },
    setOrderBy(value: ORDER_BY_TYPE) {
      self.orderBy = value
    },
    setFiltered(value: boolean | null) {
      self.filtered = value
    },
    setShowSearchResults(value: boolean) {
      self.showSearchResults = value
    },

    setMslEvents(value: MslEvent[]) {
      self.mslEvents.replace(uniqBy(prop("id"), [...self.mslEvents, ...value]))
    },

    appendMslEvent(value: MslEvent) {
      self.mslEvents.replace([value, ...self.mslEvents])
    },

    updateMslEvent(value: MslEvent) {
      const updatedMslEventsList = replaceObjectById(self.mslEvents, value)

      self.mslEvents.replace(updatedMslEventsList as MslEvent[])
    },

    deleteMslEvent(value: string) {
      const updatedMslEventsList = removeObjectById(self.mslEvents, value)
      self.mslEvents.replace(updatedMslEventsList as MslEvent[])
      self.totalCount = self.totalCount - 1
    },

    resetMslEvents() {
      if (isAlive(self.mslEvents)) {
        self.mslEvents.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetMslEvents()
      self.next = null
      self.page = PAGE
      self.pageSize = PAGE_SIZE
      self.sortBy = CREATED_AT
      self.orderBy = DESCENDING
      self.filtered = null
    },
  }))

export interface MslEventsPagination
  extends Instance<typeof MslEventsPaginationModel> {}
export interface MslEventsPaginationSnapshot
  extends SnapshotOut<typeof MslEventsPaginationModel> {}
