import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { withEnvironment, withRootStore } from "../extensions"
import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { FeedPost, FeedPostModel } from "./feed-post"

export const LIMIT = 20
export const PAGE = 1

export const ALL_POSTS = "all_posts"
export const ENGAGEMENT_PROMPT_REPLIES = "engagement_prompt_replies"
export const MUTED_POSTS = "muted_posts"
export type FILTER_BY_TYPE =
  | typeof ALL_POSTS
  | typeof ENGAGEMENT_PROMPT_REPLIES
  | typeof MUTED_POSTS

/**
 * Represents a pagination model.
 */
export const AffinityGroupFeedPaginationModel = types
  .model("AffinityGroupFeedPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    page: types.optional(types.number, PAGE),
    next: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.number, LIMIT),
    isEndReached: types.optional(types.boolean, false),
    filter: types.optional(
      types.union(
        types.literal(ALL_POSTS),
        types.literal(ENGAGEMENT_PROMPT_REPLIES),
        types.literal(MUTED_POSTS),
      ),
      ALL_POSTS,
    ),
    posts: types.optional(types.array(FeedPostModel), []),
  })
  .actions((self) => ({
    setNextPage(value: { next: number | null; isEndReached: boolean }) {
      self.next = value.next
      self.isEndReached = value.isEndReached
    },
    setPage(value: number) {
      self.page = value
    },
    setLimit(value: number) {
      self.limit = value
    },
    setFilter(value: FILTER_BY_TYPE) {
      self.filter = value
    },

    setPosts(value: FeedPost[]) {
      self.posts.replace(uniqBy(prop("id"), [...self.posts, ...value]))
    },

    updatePost(value: FeedPost) {
      const updatedPostsList = replaceObjectById(self.posts, value)

      self.posts.replace(updatedPostsList as FeedPost[])
    },

    deletePost(value: string) {
      const updatedPostsList = removeObjectById(self.posts, value)
      self.posts.replace(updatedPostsList as FeedPost[])
    },

    resetPosts() {
      if (isAlive(self.posts)) {
        self.posts.clear()
      }
    },
  }))
  .actions((self) => ({
    togglePostIsMuted(postId: string) {
      const post = self.posts.find((post) => post.id === postId)
      post.toggleIsMuted()
    },
    togglePostIsBanned(memberId: string) {
      const post = self.posts.find((post) => post.personID === memberId)
      post.toggleIsBanned()
    },
    resetPagination() {
      self.resetPosts()
      self.page = PAGE
      self.limit = LIMIT
      self.filter = ALL_POSTS
      self.next = null
      self.isEndReached = false
    },
  }))
  .actions((self) => ({
    async apiLoadAffinityGroupPosts(groupID: string) {
      const { api } = self.environment
      const { next, limit, filter, setPosts, setNextPage } = self

      if (self.isEndReached) {
        return
      }

      const { kind, data } = await api.getAffinityGroupPosts({
        groupID,
        limit,
        filter,
        timestamp: next || undefined,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const activePosts = data.data.filter(({ isDeleted }) => !isDeleted)
      const isEndReached = data.data.length < limit

      setPosts(activePosts)
      setNextPage({
        next: isEndReached ? null : data.paginationMetadata.next,
        isEndReached,
      })
    },
  }))
  .actions((self) => ({
    async apiGetAffinityGroupPosts(groupID: string) {
      await self.apiLoadAffinityGroupPosts(groupID)

      //this handles the case when the initial list contains only deleted posts
      //and the feed would not be scrollable to get the next feed items
      if (self.posts.length < 5 && !self.isEndReached) {
        self.apiLoadAffinityGroupPosts(groupID)
      }
    },
  }))
  .actions((self) => ({
    async apiPilotCoachMuteAffinityPost({
      groupID,
      postId,
    }: {
      groupID: string
      postId: string
    }) {
      const { api } = self.environment

      self.togglePostIsMuted(postId)
      const { kind, data } = await api.pilotCoachMuteAffinityPost({
        groupID,
        postId,
        muteReason: "",
      })
      if (kind !== "ok") {
        //@ts-ignore
        self.togglePostIsMuted(postId)
        throw new Error(data?.reason)
      }
    },
    async apiPilotCoachUnMuteAffinityPost({
      groupID,
      postId,
    }: {
      groupID: string
      postId: string
    }) {
      const { api } = self.environment

      self.togglePostIsMuted(postId)
      const { kind, data } = await api.pilotCoachUnMuteAffinityPost({
        groupID,
        postId,
        muteReason: "",
      })
      if (kind !== "ok") {
        //@ts-ignore
        self.togglePostIsMuted(postId)
        throw new Error(data?.reason)
      }
    },
    async apiPilotCoachBanAffinityMember({
      groupID,
      memberId,
    }: {
      groupID: string
      memberId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachBanAffinityMember({
        groupID,
        memberId,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.resetPagination()
      self.apiGetAffinityGroupPosts(groupID)
    },
    async apiPilotCoachUnBanAffinityMember({
      groupID,
      memberId,
    }: {
      groupID: string
      memberId: string
    }) {
      const { api } = self.environment

      const { kind, data } = await api.pilotCoachUnBanAffinityMember({
        groupID,
        memberId,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.resetPagination()
      self.apiGetAffinityGroupPosts(groupID)
    },
    async apiCreateAffinityFeedPost({
      groupID,
      message,
      engagementPrompt,
      inReplyTo,
    }: {
      groupID: string
      message: string
      engagementPrompt: boolean
      inReplyTo: string | null
    }) {
      const { api } = self.environment

      const { kind, data } = await api.createFeedPost({
        type: engagementPrompt ? "engagement_prompt" : "message",
        groupID: groupID,
        message: message,
        engagementPrompt: engagementPrompt,
        sessionID: null,
        inReplyTo: inReplyTo,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
    async apiDeleteAffinityFeedPost(postID: string) {
      const { api } = self.environment

      const { kind, data } = await api.deleteFeedPost({ postID: postID })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
    },
  }))
  .views((self) => ({
    get isSelectiveFilterActive(): boolean {
      return self.filter !== ALL_POSTS
    },
    get activeQuestionOfTheDay() {
      const postsList = [...self.posts]
      const firstItem = postsList[0]
      if (firstItem?.type === "engagement_prompt") {
        return firstItem
      }
      return null
    },
  }))
  .views((self) => ({
    get feedPostsList() {
      if (self.activeQuestionOfTheDay) {
        return self.posts.slice(1)
      }
      return self.posts
    },
  }))

export interface AffinityGroupFeedPagination
  extends Instance<typeof AffinityGroupFeedPaginationModel> {}
export interface AffinityGroupFeedPaginationSnapshot
  extends SnapshotOut<typeof AffinityGroupFeedPaginationModel> {}
