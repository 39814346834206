import React, { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import {
  Theme,
  Stack,
  LinearProgress,
  Button,
  Typography,
} from "@material-ui/core"
import LoadingButton from "@mui/lab/LoadingButton"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { UploadFile } from "../../models/upload-file"
import { getUrlFileType } from "../../utils/fileType-url"

export interface FilePickerProps {
  onChange: (
    file: Omit<
      UploadFile,
      "setUploadFileProgress" | "setUploadFileError" | "reset"
    >,
  ) => void
  label: string
  fileType: string
  inputId: string
  fileUrl: string
  uploadFiles: UploadFile
  mode?: ("image" | "video" | "audio")[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filePickerContainer: {
      marginTop: theme.spacing(2),
    },
    input: { display: "none" },
    removeButton: {
      marginBottom: theme.spacing(1),
    },
    previewContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    preview: {
      maxWidth: "100%",
      maxHeight: 300,
      marginBottom: theme.spacing(1),
    },
    error: {
      marginTop: theme.spacing(1),
      fontWeight: "bold",
      color: theme.palette.error.light,
    },
  }),
)

const FilePicker: React.FC<FilePickerProps> = observer(
  ({
    onChange,
    label,
    fileType,
    inputId,
    fileUrl,
    uploadFiles,
    mode = ["image"],
  }) => {
    const classes = useStyles()
    const inputRef = useRef<HTMLInputElement>(null)
    const [previewFile, setPreviewFile] = useState<{
      url: string
      type: string
    } | null>(null)

    useEffect(() => {
      return () => {
        if (inputRef.current) {
          inputRef.current = null
        }
      }
    }, [])

    const { reset, progress, error } = uploadFiles

    const isLoading = progress > 0 && progress <= 100
    const showFilePreview = previewFile || fileUrl
    const pickerMode = mode.map((m) => `${m}/*`).join(", ")

    const handleFileInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const file = event.target.files[0]

      setPreviewFile({ url: URL.createObjectURL(file), type: file.type })

      onChange({
        id: inputId,
        file: file,
        fileType,
        progress: 0,
        error: "",
        fileName: file.name,
        url: "",
      })
    }

    const handleClick = () => {
      if (inputRef.current) {
        inputRef.current.click()
      }
    }

    const handleRemove = () => {
      setPreviewFile(null)
      reset()
    }

    const renderPreview = () => {
      const fileMode = previewFile
        ? previewFile?.type.split("/")[0]
        : getUrlFileType(fileUrl)

      const fileContent = previewFile?.url ?? fileUrl

      switch (fileMode) {
        case "image":
          return (
            <img src={fileContent} alt="Preview" className={classes.preview} />
          )
        case "video":
          return (
            <video controls className={classes.preview}>
              <source src={fileContent} />
            </video>
          )
        case "audio":
          return (
            <audio controls className={classes.preview}>
              <source src={fileContent} />
            </audio>
          )
        default:
          return (
            <img src={fileContent} alt="Preview" className={classes.preview} />
          )
      }
    }

    return (
      <Stack className={classes.filePickerContainer}>
        <input
          className={classes.input}
          type="file"
          accept={pickerMode}
          ref={inputRef}
          onChange={handleFileInputChange}
        />

        {showFilePreview && (
          <div className={classes.previewContainer}>
            {renderPreview()}

            {previewFile && (
              <Button
                className={classes.removeButton}
                variant="outlined"
                color="error"
                onClick={handleRemove}
                disabled={isLoading}>
                Remove
              </Button>
            )}
          </div>
        )}

        {isLoading && <LinearProgress variant="determinate" value={progress} />}

        <Typography className={classes.error}>{error}</Typography>

        <LoadingButton
          variant="contained"
          component="span"
          onClick={handleClick}
          startIcon={<CloudUploadIcon />}
          loading={isLoading}>
          {label}
        </LoadingButton>
      </Stack>
    )
  },
)

export { FilePicker }
