import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ShortText } from "../admin-msl-events-list/short-text"
import { TriProductActions } from "./tri-product-actions"
import { useStores } from "../../models/root-store"
import { TriProduct } from "../../models/tri-product"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTriProductModal?: (event: TriProduct) => void
  triProductID?: string
}
const CellComponent = ({
  openEditTriProductModal,
  triProductID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { triProductsPagination } = adminMslEventStoreModel

  const triProduct = triProductsPagination.products.find(
    (product) => product.id === triProductID,
  )

  switch (props.field) {
    case "createdAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "updatedAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "actions":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditTriProductModal(triProduct)}>
            Edit
          </Button>

          <TriProductActions triProductID={triProductID} />
        </Stack>
      )

    case "isBackup":
      return <>{Boolean(props.value).toString()}</>

    case "isStale":
      return <>{Boolean(props.value).toString()}</>

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
