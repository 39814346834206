import { useState } from "react"
import {
  Stack,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { Module } from "../../models/tri-content-modules"
import { ReactComponent as DeleteSessionIcon } from "../../assets/images/delete_session_icon.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
    image: {
      width: "100%",
      maxWidth: 100,
    },
    largeImage: {
      width: "100%",
      maxWidth: 200,
    },
  }),
)

interface TriModulesActionsProps {
  onEdit?: (event: Module) => void
  moduleID: string
}

const TriModulesActions = ({ onEdit, moduleID }: TriModulesActionsProps) => {
  const classes = useStyles()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)

  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const { triContentModules } = adminMslEventStoreModel
  const { modules, apiAdminDeleteTriModule } = triContentModules
  const module = modules.find(({ id }) => id === moduleID)

  const handleDeleteModule = async () => {
    try {
      toggleDeleteDialog(false)
      setLoadingDelete(true)
      await apiAdminDeleteTriModule(moduleID)
      setAndShowSnackbar({
        severity: "success",
        text: `Successfully deleted ${module.title}`!,
      })
      setLoadingDelete(false)
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
      setLoadingDelete(false)
    }
  }

  return (
    <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
      <Button
        variant="contained"
        className={classes.actionButton}
        onClick={() => onEdit(module)}>
        Edit
      </Button>

      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        spacing={1}>
        <Dialog
          open={isDeleteDialogOpen}
          aria-labelledby="alert-delete-tri-module-title">
          <DialogTitle id="alert-delete-tri-module-title">
            Are you sure you want to delete {module.title}?
          </DialogTitle>

          <DialogActions>
            <Button onClick={() => toggleDeleteDialog(false)}>Cancel</Button>

            <Button autoFocus onClick={handleDeleteModule}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton
          disabled={loadingDelete}
          onClick={() => toggleDeleteDialog(true)}>
          {loadingDelete ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            <DeleteSessionIcon width={18} height={18} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  )
}

export { TriModulesActions }
