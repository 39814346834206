import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { ShortText } from "../admin-msl-events-list/short-text"
import { TriBehaviorActions } from "./tri-behavior-actions"
import { useStores } from "../../models/root-store"
import { TriBehavior } from "../../models/tri-behavior"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
    image: { width: "100%" },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTriBehaviorModal?: (event: TriBehavior) => void
  triBehaviorID?: string
}
const CellComponent = ({
  openEditTriBehaviorModal,
  triBehaviorID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { triBehaviorsPagination } = adminMslEventStoreModel

  const triBehavior = triBehaviorsPagination.behaviors.find(
    (behavior) => behavior.id === triBehaviorID,
  )

  switch (props.field) {
    case "createdAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "iconURI":
      return (
        <>
          {!!props.value ? (
            <img src={props.value} alt="poster" className={classes.image} />
          ) : (
            "N/A"
          )}
        </>
      )

    case "actions":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditTriBehaviorModal(triBehavior)}>
            Edit
          </Button>

          <TriBehaviorActions triBehaviorID={triBehaviorID} />
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
