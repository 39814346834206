import { Theme } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { typography } from "../../services/theme/typography"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coachName: {
      fontSize: theme.spacing(2.25),
      fontFamily: typography.circularXXMedium.fontFamily,
      color: "#FFFFFF",
    },
    profileButton: {
      background: theme.palette.common.white,
      "&:hover": { background: theme.palette.common.white },
      padding: theme.spacing(1.25, 2.5, 1.25, 4),
      borderRadius: theme.spacing(4),
    },
    menuIcon: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      fill: "#1771A6",
    },
    profileMenuContainer: {
      width: 260,
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
    divider: {
      width: "100%",
      height: 2,
      background: "#24645C1A",
    },
    editProfileButton: {
      width: "100%",
      padding: theme.spacing(3, 2),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#1771A6",
      fontSize: theme.spacing(2.25),
      ...typography.circularXXMedium,
    },
    logoutButton: {
      width: "100%",
      padding: theme.spacing(3, 2),
      display: "flex",
      justifyContent: "space-between",
      color: "#F36D4F",
      fontSize: theme.spacing(2.25),
      ...typography.circularXXMedium,
    },
    logoutIcon: {
      height: theme.spacing(3),
      background: "#F36D4F",
      color: theme.palette.common.white,
      borderRadius: "50%",
    },
    profileBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#1F70CE",
      borderRadius: theme.spacing(4),
      padding: theme.spacing(1, 3),
      gap: theme.spacing(2.5),
    },
    avatar: {
      width: theme.spacing(5),
      borderRadius: theme.spacing(2.5),
      border: "2px solid white",
      "& img": {
        objectFit: "contain",
      },
    },
    toggleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: theme.spacing(5.5),
      overflow: "hidden",
      padding: theme.spacing(0.25),
      columnGap: theme.spacing(0.2),
    },
    toggleButton: {
      fontSize: theme.spacing(1.5),
      textTransform: "none",
      fontFamily: typography.circularXXMedium.fontFamily,
      borderRadius: `${theme.spacing(2.5)} !important`,
      padding: theme.spacing(0.75),
      transition: "color 0.3s ease, transform 0.3s ease",

      "&.Mui-selected": {
        fontWeight: 700,
        color: "#FFFFFF !important",
        transform: "scale(1)",
      },
      "&.MuiToggleButton-root": {
        backgroundColor: "#FFFFFF",
        color: "#677278",
        border: "none",
      },
    },
    onlineSelected: {
      backgroundColor: "#00CC7E !important",
    },
    offlineSelected: {
      backgroundColor: "#485359 !important",
    },
    hamburgerIcon: {
      fontSize: theme.spacing(5),
    },
    iconButton: {
      padding: 0,
    },
  }),
)
