import { useCallback } from "react"
import { GridSortModel } from "@mui/x-data-grid"
import { SORT_TITLE } from "../../models/tri-categories-pagination"

interface UseTableParams {
  fetchCategories: () => Promise<void>
  resetPagination: () => void
  setLoading: (loading: boolean) => void
  setSortBy: (sortBy: string) => void
}

const useTable = ({
  fetchCategories,
  resetPagination,
  setLoading,
  setSortBy,
}: UseTableParams) => {
  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      const sortOption = sortModel[0]
      if (!sortOption) {
        resetPagination()
        fetchCategories()
        return
      }
      resetPagination()
      setSortBy(SORT_TITLE)
      fetchCategories()
    },
    [fetchCategories, resetPagination, setLoading, setSortBy],
  )

  return {
    handleSortModelChange,
  }
}

export { useTable }
