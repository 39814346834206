import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { uniqBy, prop } from "ramda"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { Person, PersonModel } from "../person"

export const LIMIT = 20
export const PAGE = 1
export const ASCENDING = "asc"
export const DESCENDING = "desc"
export type ORDER_BY_TYPE = typeof ASCENDING | typeof DESCENDING

/**
 * Represents a pagination model.
 */
export const InactiveParticipantsPaginationModel = types
  .model("InactiveParticipantsPagination")
  .props({
    page: types.optional(types.number, PAGE),
    next: types.optional(types.maybeNull(types.string), null),
    limit: types.optional(types.number, LIMIT),
    isEndReached: types.optional(types.boolean, false),
    order: types.optional(
      types.union(types.literal(ASCENDING), types.literal(DESCENDING)),
      DESCENDING,
    ),
    participants: types.optional(types.array(PersonModel), []),
  })
  .actions((self) => ({
    setNextPage(value: { next: string | null; isEndReached: boolean }) {
      self.next = value.next
      self.isEndReached = value.isEndReached
    },
    setPage(value: number) {
      self.page = value
    },
    setLimit(value: number) {
      self.limit = value
    },
    setOrderBy(value: ORDER_BY_TYPE) {
      self.order = value
    },

    setParticipants(value: Person[]) {
      self.participants.replace(
        uniqBy(prop("id"), [...self.participants, ...value]),
      )
    },

    updateParticipant(value: Person) {
      const updatedParticipantsList = replaceObjectById(
        self.participants,
        value,
      )

      self.participants.replace(updatedParticipantsList as Person[])
    },

    deleteParticipant(value: string) {
      const updatedParticipantsList = removeObjectById(self.participants, value)
      self.participants.replace(updatedParticipantsList as Person[])
    },

    resetParticipants() {
      if (isAlive(self.participants)) {
        self.participants.clear()
      }
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetParticipants()
      self.page = PAGE
      self.limit = LIMIT
      self.order = DESCENDING
      self.next = null
      self.isEndReached = false
    },
  }))

export interface InactiveParticipantsPagination
  extends Instance<typeof InactiveParticipantsPaginationModel> {}
export interface InactiveParticipantsPaginationSnapshot
  extends SnapshotOut<typeof InactiveParticipantsPaginationModel> {}
