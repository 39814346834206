import { useRef } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"
import { debounce } from "rambdax"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-category-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { Tag } from "../../models/tag"
import { TriIdea } from "../../models/tri-idea"
import { TriBehavior } from "../../models/tri-behavior"

const useTriCategoryForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    tags: tagsOptions,
    triIdeasPagination,
    triCategoriesPagination,
    triBehaviorsPagination,
  } = adminMslEventStoreModel

  const {
    newTriCategory,
    apiAdminCreateTriCategory,
    apiAdminEditTriCategory,
    apiAdminAddTagToTriCategory,
    apiAdminDeleteTriCategoryTag,
    apiAdminAddIdeaToTriCategory,
    apiAdminDeleteTriCategoryIdea,
    apiAdminAddBehaviorToTriCategory,
    apiAdminDeleteTriCategoryBehavior,
    resetNewTriCategory,
  } = triCategoriesPagination

  const {
    tags,
    ideas,
    behaviors,
    setTags,
    setIdeas,
    setBehaviors,
    createTriCategoryErrors: errors,
  } = newTriCategory

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToTriCategory(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriCategoryTag(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleIdeasChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriIdea[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdea>,
  ) => {
    event.preventDefault()
    const areValidIdeas = value.every((e) => e?.id)
    if (areValidIdeas) {
      setIdeas(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        console.log(option)
        await apiAdminAddIdeaToTriCategory(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} idea !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriCategoryIdea(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} idea !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleBehaviorChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriBehavior[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdea>,
  ) => {
    const areValidBehaviors = value.every((e) => e?.id)
    if (areValidBehaviors) {
      setBehaviors(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddBehaviorToTriCategory(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} behavior !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriCategoryBehavior(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} behavior !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string, searchAPI) => {
      try {
        await searchAPI()
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 2000),
  )

  const onCancel = () => {
    resetNewTriCategory()
    triIdeasPagination.resetTriIdeas()
    triBehaviorsPagination.resetTriBehaviors()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriCategory()
        setAndShowSnackbar({
          text: "Successfully created new category !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriCategory()
        setAndShowSnackbar({
          text: "Successfully edited category !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleIdeasSearch = async (_, value, isEndReached) => {
    const {
      next,
      setQuery,
      resetPagination,
      apiAdminGetTriIdeas,
      apiAdminGetTriIdeasSearch,
    } = triIdeasPagination

    if (isEndReached) {
      if (next) {
        await apiAdminGetTriIdeas()
      }
    } else {
      if (value.trim().length) {
        resetPagination()
        const fetchNewIdeas = () => {
          setQuery(value)
          apiAdminGetTriIdeasSearch()
        }

        debouncedSearch.current(value, fetchNewIdeas)
      }
    }
  }

  const handleBehaviorsSearch = async (_, value, isEndReached) => {
    const {
      next,
      setQuery,
      apiAdminGetTriBehaviors,
      apiAdminGetTriBehaviorsSearch,
      resetPagination,
    } = triBehaviorsPagination

    if (isEndReached) {
      if (next) {
        await apiAdminGetTriBehaviors()
      }
    } else {
      if (value.trim().length) {
        resetPagination()
        const fetchNewBehaviors = () => {
          setQuery(value)
          apiAdminGetTriBehaviorsSearch()
        }
        debouncedSearch.current(value, fetchNewBehaviors)
      }
    }
  }

  const handleFetchIdeas = (filterType, value) => {
    const {
      setFilterBy,
      setSortBy,
      setLimit,
      setNext,
      apiAdminGetTriIdeas,
    } = triIdeasPagination

    setNext(0)

    switch (filterType) {
      case "filterBy":
        setFilterBy(value)
        apiAdminGetTriIdeas()
        break

      case "sortBy":
        setSortBy(value)
        apiAdminGetTriIdeas()
        break

      case "limit":
        setLimit(value)
        apiAdminGetTriIdeas()
        break

      default:
        setLimit(value)
        apiAdminGetTriIdeas()
        break
    }
  }

  const handleBehaviorFilters = (filterType, value) => {
    const { setSortBy, setLimit } = triBehaviorsPagination

    switch (filterType) {
      case "sortBy":
        setSortBy(value)
        break

      case "limit":
        setLimit(value)
        break

      default:
        setLimit(value)
        break
    }
  }

  const handleOnBlurSelect = () => {
    triBehaviorsPagination.resetTriBehaviors()
  }

  const handleSelectFocus = () => {
    triBehaviorsPagination.resetTriBehaviors()
    triBehaviorsPagination.setNext(null)
    triBehaviorsPagination.setPrevious(null)
    triBehaviorsPagination.apiAdminGetTriBehaviors()
  }

  const handleOnBlurIdeasSelect = () => {
    triIdeasPagination.resetTriIdeas()
  }

  const handleSelectIdeasFocus = () => {
    triIdeasPagination.resetTriIdeas()
    triIdeasPagination.setNext(null)
    triIdeasPagination.setPrevious(null)
    triIdeasPagination.apiAdminGetTriIdeas()
  }

  const behaviorOptions = triBehaviorsPagination.behaviors.slice()
  const behaviorsValue = behaviors.slice()

  const ideasOptions = triIdeasPagination.ideas.slice()
  const ideasValues = ideas.slice()

  const triCategoryFormConfig = [
    {
      fieldName: "title",
      onChangeMethod: "setTitle",
      label: "Title",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title",
      },
    },
    {
      fieldName: "titleBareInfinitive",
      onChangeMethod: "setTitleBareInfinitive",
      label: "Title Bare Infinitive",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive",
      },
    },
    {
      fieldName: "titleBareInfinitive2ndPerson",
      onChangeMethod: "setTitleBareInfinitive2ndPerson",
      label: "Title Bare Infinitive 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive 2nd person",
      },
    },
    {
      fieldName: "titlePresentContinuous",
      onChangeMethod: "setTitlePresentContinuous",
      label: "Title Present Continuous",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous",
      },
    },
    {
      fieldName: "titlePresentContinuous2ndPerson",
      onChangeMethod: "setTitlePresentContinuous2ndPerson",
      label: "Title Present Continuous 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous 2nd person",
      },
    },
    {
      fieldName: "summary",
      onChangeMethod: "setSummary",
      label: "Summary",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title summary",
      },
    },
    {
      fieldName: "iconURI",
      onChangeMethod: "setIconURI",
      label: "Icon URI",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter icon URI",
      },
    },

    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },

    {
      fieldName: "ideas",
      onChangeMethod: "setIdeas",
      label: "Ideas",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: ideasOptions,
        value: ideasValues,
        open: ideasOptions.length > 0,
        onChange: handleIdeasChange,
        loading: triIdeasPagination.loading,
        onInputChange: (_, value) => handleIdeasSearch(_, value, false),
        onScrollEnd: () => handleIdeasSearch(null, null, true),
        onBlur: handleOnBlurIdeasSelect,
        onFocus: handleSelectIdeasFocus,
        renderOption: (props, option: TriIdea) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        getOptionLabel: (option: TriIdea) => option.title,
        filters: [
          {
            label: "Filter by",
            key: "filterBy",
            value: triIdeasPagination.filterBy,
            options: [
              { label: "Active", value: "active" },
              { label: "Recipe", value: "recipe" },
              { label: "Hack", value: "hack" },
              { label: "Iteration", value: "iteration" },
              { label: "Setup", value: "setup" },
              { label: "Product", value: "product" },
              { label: "Video", value: "video" },
              { label: "Insight", value: "insight" },
            ],
          },
          {
            label: "Sort by",
            key: "sortBy",
            value: triIdeasPagination.sortBy,
            options: [
              { label: "Title", value: "title" },
              { label: "Created At", value: "createdAt" },
            ],
          },
          {
            label: "Limit",
            key: "limit",
            value: triIdeasPagination.limit.toString(),
            options: [
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ],
          },
        ],
        onFilterChange: (filterName, filterValue) => {
          handleFetchIdeas(filterName, filterValue)
        },
        Input: {
          placeholder: "Select Ideas",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    },

    {
      fieldName: "behaviors",
      onChangeMethod: "setBehaviors",
      label: "Behaviors",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: behaviorOptions,
        value: behaviorsValue,
        open: behaviorOptions.length > 0,
        onChange: handleBehaviorChange,
        loading: triBehaviorsPagination.loading,
        onInputChange: (_, value) => handleBehaviorsSearch(_, value, false),
        onScrollEnd: () => handleBehaviorsSearch(null, null, true),
        onBlur: handleOnBlurSelect,
        onFocus: handleSelectFocus,
        renderOption: (props, option: TriBehavior) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        getOptionLabel: (option: TriBehavior) => option.title,
        filters: [
          {
            label: "Sort by",
            key: "sortBy",
            value: triBehaviorsPagination.sortBy,
            options: [
              { label: "Title", value: "title" },
              { label: "Created At", value: "createdAt" },
            ],
          },
          {
            label: "Limit",
            key: "limit",
            value: triBehaviorsPagination.limit,
            options: [
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ],
          },
        ],
        onFilterChange: (filterName, filterValue) => {
          handleBehaviorFilters(filterName, filterValue)
        },
        Input: {
          placeholder: "Select Behaviors",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    newTriCategory,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: newTriCategory,
    errors,
    triCategoryFormConfig,
  }
}

export { useTriCategoryForm }
