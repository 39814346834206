import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { PRODUCTS_SORT_TYPE, SORT_CREATED_AT, SORT_TITLE } from "./filter-types"
import { TriProduct, TriProductModel } from "../tri-product/tri-product"

export const LIMIT = 25

/**
 * Represents a pagination model.
 */
export const TriProductsPaginationModel = types
  .model("TriProductsPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    loading: types.optional(types.boolean, false),
    page: types.optional(types.maybeNull(types.number), null),
    pageSize: types.optional(types.maybeNull(types.number), LIMIT),
    sortBy: types.optional(
      types.union(types.literal(SORT_CREATED_AT), types.literal(SORT_TITLE)),
      SORT_CREATED_AT,
    ),
    totalCount: types.optional(types.number, 0),
    products: types.optional(types.array(TriProductModel), []),
    newTriProduct: types.optional(TriProductModel, () =>
      TriProductModel.create({
        id: "-1",
      }),
    ),
    nestedTriProduct: types.optional(TriProductModel, () =>
      TriProductModel.create({
        id: "-1",
      }),
    ),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setPage(value: number | null) {
      self.page = value
    },
    setPageSize(value: number | null) {
      self.pageSize = value
    },
    setSortBy(value: PRODUCTS_SORT_TYPE) {
      self.sortBy = value
    },
    setTotalCount(value: number) {
      self.totalCount = value
    },
    setNewTriProduct(value: TriProduct) {
      self.newTriProduct = clone(value)
    },
    setNestedriProduct(value: TriProduct) {
      self.nestedTriProduct = clone(value)
    },

    setPagination(value: {
      page: number
      pageSize: number
      sortBy: PRODUCTS_SORT_TYPE
      totalCount?: number
    }) {
      self.page = value.page
      self.pageSize = value.pageSize
      self.sortBy = value.sortBy
      self.totalCount = value.totalCount
    },

    setTriProducts(value: TriProduct[]) {
      self.products.replace(value)
    },

    updateTriProduct(value: TriProduct) {
      const updatedTriProductsList = replaceObjectById(self.products, value)
      self.products.replace(updatedTriProductsList as TriProduct[])
    },

    deleteTriProduct(value: string) {
      const updatedTriProductsList = removeObjectById(self.products, value)
      self.products.replace(updatedTriProductsList as TriProduct[])
      self.totalCount = self.totalCount - 1
    },

    resetNewTriProduct() {
      self.newTriProduct = TriProductModel.create({
        id: "-1",
      })
    },
    resetNestedTriProduct() {
      self.nestedTriProduct = TriProductModel.create({
        id: "-1",
      })
    },

    resetTriProducts() {
      if (isAlive(self.products)) {
        self.products.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriProducts() {
      const { api } = self.environment
      const { page, pageSize, sortBy, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriProducts({
        page,
        pageSize,
        sortBy,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setTotalCount(data.paginationMetadata.totalCount)
      self.setTriProducts(data.data)
      setLoading(false)
    },

    async apiAdminGetSingleTriProduct(params: {
      productID: string
      isNestedProduct: boolean
    }) {
      const { api } = self.environment
      const { kind, data } = await api.adminGetSingleTriProduct(params)

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const product = TriProductModel.create(data.data)

      if (params.isNestedProduct) {
        self.setNestedriProduct(product)
      } else {
        self.setNewTriProduct(product)
      }
    },
  }))
  .actions((self) => ({
    async apiAdminCreateTriProduct(): Promise<void> {
      const { api } = self.environment

      const {
        id,
        createdAt,
        updatedAt,
        isStale,
        backups,
        ...newProductParams
      } = self.newTriProduct
      const { data, kind } = await api.adminCreateTriProduct(newProductParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriProducts()
      self.resetNewTriProduct()
    },

    async apiAdminEditTriProduct(params: {
      isNestedProduct: boolean
    }): Promise<void> {
      const { api } = self.environment

      const formFields = params.isNestedProduct
        ? self.nestedTriProduct
        : self.newTriProduct
      const { createdAt, updatedAt, ...updateParams } = formFields

      const { data, kind } = await api.adminEditTriProduct(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetTriProducts()

      if (params.isNestedProduct) {
        self.resetNestedTriProduct()
      } else {
        self.resetNewTriProduct()
      }
    },

    async apiAdminRemoveBackupProduct(backup: TriProduct): Promise<void> {
      const { api } = self.environment

      const { createdAt, updatedAt, ...updateParams } = backup

      const { data, kind } = await api.adminEditTriProduct(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      if (self.nestedTriProduct.id !== "-1") {
        self.apiAdminGetSingleTriProduct({
          productID: self.nestedTriProduct.id,
          isNestedProduct: true,
        })
      } else {
        self.apiAdminGetSingleTriProduct({
          productID: self.newTriProduct.id,
          isNestedProduct: false,
        })
      }
    },

    async apiAdminDeleteTriProduct(productID: string): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriProduct({
        productID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.deleteTriProduct(productID)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriProducts()
      self.pageSize = LIMIT
      self.loading = false
      self.totalCount = 0
      self.page = null
      self.sortBy = SORT_CREATED_AT
    },
  }))

export interface TriProductsPagination
  extends Instance<typeof TriProductsPaginationModel> {}
export interface TriProductsPaginationSnapshot
  extends SnapshotOut<typeof TriProductsPaginationModel> {}
