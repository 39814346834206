import React from "react"
import { Link, useParams } from "react-router-dom"
import { Button, Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { typography } from "../../services/theme/typography"

interface ChatButtonProps {
  personID: string
  siteId: string
  cohortId: string
  newMessages?: number
}

interface PersonDetailsRouteParams {
  siteId: string
  cohortId: string
  personId: string
  groupId: string
  coachId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      border: "1px #1771A6 solid",
      width: 170,
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      color: "#1771A6",
      ...typography.circularXXBook,
    },

    unreadBadge: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      borderRadius: 12,
      backgroundColor: "#F23D3C",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.spacing(1.7),
      marginRight: theme.spacing(1.87),
    },
  }),
)

const ChatButton = ({
  personID,
  siteId,
  cohortId,
  newMessages,
}: ChatButtonProps) => {
  const classes = useStyles()
  const params = useParams<PersonDetailsRouteParams>()

  if (!Boolean(personID)) {
    return <div />
  }

  const getRedirectLink = () => {
    if (!cohortId) {
      return `/pilot-trainer/${params.coachId}/group/${params.groupId}/chat/${personID}`
    }

    return `/site/${siteId}/cohorts/${cohortId}/chat/${personID}`
  }

  return (
    <>
      <Link to={getRedirectLink()}>
        <Button className={classes.button}>
          {Boolean(newMessages) && (
            <Typography className={classes.unreadBadge}>
              {newMessages}
            </Typography>
          )}
          Message
        </Button>
      </Link>
    </>
  )
}

export { ChatButton }
