import React from "react"
import {
  Theme,
  Autocomplete,
  AutocompleteProps,
  Chip,
  Stack,
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import { FormInput, FormInputProps } from "./form-input"

type OptionType = { id: string; [key: string]: any }
type SelectFieldType =
  | OptionType
  | NonNullable<string | OptionType>
  | (string | OptionType)[]

export interface FilterOption {
  label: string
  value: string | number
  key: string
  options: { label: string; value: string | number }[]
}

export interface FormSelectProps
  extends Omit<
    AutocompleteProps<SelectFieldType, boolean, boolean, boolean>,
    "renderInput"
  > {
  Input?: FormInputProps
  forbidDuplicates?: boolean
  showTags?: boolean
  tagDisplayNameField?: string
  selectedOptionContent?: React.ReactNode
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => void
  onScrollEnd?: () => void
  loading?: boolean
  filters?: FilterOption[]
  onFilterChange?: (filterName: string, filterValue: string | number) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  formControl: {
    width: "100%",
  },
  filterContainer: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filter: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
}))

//filter options that are already selected
const filterDuplicates = (options: OptionType[], value: SelectFieldType) => {
  if (Array.isArray(value)) {
    return options.filter(
      (option) => !value.find((e) => (e as OptionType)?.id === option.id),
    )
  }
  return options
}

const FormSelect = ({
  value,
  options,
  Input,
  forbidDuplicates,
  showTags,
  tagDisplayNameField,
  onInputChange,
  onScrollEnd,
  loading,
  filters = [],
  onFilterChange,
  ...props
}: FormSelectProps) => {
  const classes = useStyles()

  const [filterValues, setFilterValues] = React.useState(
    filters.reduce(
      (acc, filter) => ({ ...acc, [filter.key]: filter.value }),
      {},
    ),
  )

  const selectOptions = forbidDuplicates
    ? filterDuplicates(options as OptionType[], value as SelectFieldType)
    : options
  //in case we're showing tags, and if the tags list is filled, hide the placeholder
  const hideInputPlaceholder =
    showTags && (value as SelectFieldType[])?.length > 0

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listbox = event.currentTarget

    if (listbox) {
      const { scrollTop, scrollHeight, clientHeight } = listbox

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        onScrollEnd && onScrollEnd()
      }
    }
  }

  const handleFilterChange = (filterName: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value
    setFilterValues((prevValues) => ({
      ...prevValues,
      [filterName]: newValue,
    }))
    onFilterChange && onFilterChange(filterName, newValue)
  }

  return (
    <Stack width={"100%"}>
      <div className={classes.filterContainer}>
        {filters.map((filter) => (
          <TextField
            key={filter.value}
            select
            label={filter.label}
            value={filterValues[filter.key]}
            onChange={handleFilterChange(filter.key)}
            variant="outlined"
            size="small"
            className={classes.filter}>
            {filter.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ))}
      </div>

      <Autocomplete
        value={value}
        options={selectOptions}
        freeSolo
        disableClearable
        loading={loading}
        clearIcon={null}
        onInputChange={(event, newInputValue) => {
          onInputChange && onInputChange(event, newInputValue)
        }}
        classes={{
          root: classes.formControl,
        }}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        renderTags={
          showTags && tagDisplayNameField
            ? (value, getTagProps) =>
                value.map((option: OptionType, index) => (
                  <Chip
                    label={option[tagDisplayNameField]}
                    color="secondary"
                    {...getTagProps({ index })}
                  />
                ))
            : () => <div />
        }
        renderInput={(params) => (
          <FormInput
            {...params}
            {...Input}
            placeholder={hideInputPlaceholder ? "" : Input?.placeholder}
            InputProps={{
              ...params.InputProps,
              ...Input?.InputProps,
              type: "search",
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? <CircularProgress size={20} /> : null}
                </InputAdornment>
              ),
            }}
          />
        )}
        {...props}
      />

      {props.selectedOptionContent}
    </Stack>
  )
}

export { FormSelect }
