import React, { FC } from "react"
import { Popover, Button, Stack } from "@material-ui/core"
import { Link } from "react-router-dom"
import PersonIcon from "@mui/icons-material/Person"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"
import { useStyles } from "./useStyles"

type ProfilePopoverProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
  onLogout: () => void
}

const ProfilePopover: FC<ProfilePopoverProps> = ({
  anchorEl,
  onClose,
  onLogout,
}) => {
  const classes = useStyles()

  return (
    <Popover
      id={Boolean(anchorEl) ? "profile-menu" : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{ paper: classes.profileMenuContainer }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}>
      <Link to="/profile-page">
        <Button className={classes.editProfileButton}>
          <Stack spacing={1.5} direction="row" alignItems="center" flex={1}>
            <PersonIcon />
            <span>Edit Profile</span>
          </Stack>
          <NavigateNextIcon />
        </Button>
      </Link>
      <div className={classes.divider} />
      <Button className={classes.logoutButton} onClick={onLogout}>
        <Stack spacing={1.5} direction="row" alignItems="center" flex={1}>
          <span className={classes.logoutIcon}>
            <PowerSettingsNewIcon />
          </span>
          <span>Sign out</span>
        </Stack>
      </Button>
    </Popover>
  )
}

export { ProfilePopover }
