import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

import { TriIdea, TriIdeaModel } from "../tri-idea"
import { Tag, TagModel } from "../tag"
import { TriBehavior, TriBehaviorModel } from "../tri-behavior"

export const TriCategoryModel = types
  .model("TriCategory")
  .props({
    id: types.identifier,
    title: types.optional(types.maybeNull(types.string), ""),
    summary: types.optional(types.maybeNull(types.string), null),
    iconURI: types.optional(types.maybeNull(types.string), ""),
    titleBareInfinitive: types.optional(types.maybeNull(types.string), null),
    titleBareInfinitive2ndPerson: types.optional(
      types.maybeNull(types.string),
      null,
    ),
    titlePresentContinuous: types.optional(types.maybeNull(types.string), null),
    titlePresentContinuous2ndPerson: types.optional(
      types.maybeNull(types.string),
      null,
    ),
    behaviors: types.optional(types.array(TriBehaviorModel), []),
    ideas: types.optional(types.array(TriIdeaModel), []),
    tags: types.optional(types.array(TagModel), []),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setTitle(value: string) {
      self.title = value
    },
    setSummary(value: string) {
      self.summary = value
    },
    setIconURI(value: string) {
      self.iconURI = value
    },
    setTitleBareInfinitive(value: string) {
      self.titleBareInfinitive = value
    },
    setTitleBareInfinitive2ndPerson(value: string) {
      self.titleBareInfinitive2ndPerson = value
    },
    setTitlePresentContinuous(value: string) {
      self.titlePresentContinuous = value
    },
    setTitlePresentContinuous2ndPerson(value: string) {
      self.titlePresentContinuous2ndPerson = value
    },
    setBehaviors(value: TriBehavior[]) {
      self.behaviors.replace(value.map((e) => clone(e)))
    },
    setIdeas(value: TriIdea[]) {
      self.ideas.replace(value.map((e) => clone(e)))
    },
    setTags(value: Tag[]) {
      self.tags.replace(value.map((e) => clone(e)))
    },

    reset() {
      self.id = "-1"
      self.title = ""
      self.summary = null
      self.iconURI = null
      self.titlePresentContinuous = null
      self.titleBareInfinitive = null
      self.titleBareInfinitive2ndPerson = null
      self.titlePresentContinuous2ndPerson = null
      self.behaviors.replace([])
      self.ideas.replace([])
      self.tags.replace([])
      self.createdAt = null
      self.updatedAt = null
    },
  }))
  .views((self) => ({
    get createTriCategoryErrors() {
      return validate(CREATE_NEW_TRI_CATEGORY_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TRI_CATEGORY_RULES: ValidationRules = {
  title: {
    presence: { allowEmpty: false, message: "required" },
  },
  summary: {
    presence: { allowEmpty: false, message: "required" },
  },
  iconURI: {
    presence: { allowEmpty: false, message: "required" },
  },
  titleBareInfinitive: {
    presence: { allowEmpty: false, message: "required" },
  },
  titlePresentContinuous: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface TriCategory extends Instance<typeof TriCategoryModel> {}
export interface TriCategorySnapshot
  extends SnapshotOut<typeof TriCategoryModel> {}
