import React from "react"
import { useRouteMatch } from "react-router-dom"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme } from "@material-ui/core"
import { AffinityTab } from "./affinity-tab"

interface RouteParams {
  affinityGroupId: string
  coachId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "100%",
      background: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1.5, 0, 0, 3),
      borderBottom: "1px solid #C7CFD4",
    },
  }),
)

const AffinityTabsBar = () => {
  const classes = useStyles()

  const matchAffinityRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId",
  )
  const { affinityGroupId } = matchAffinityRoute?.params || {}

  if (!affinityGroupId) {
    return <div />
  }

  return (
    <div className={classes.content}>
      <AffinityTab route="/feed" label="Main" />

      <AffinityTab route="/chats" label="Chats" />

      <AffinityTab route="/members" label="Members" />
    </div>
  )
}

export { AffinityTabsBar }
