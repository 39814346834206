import React, { useState } from "react"
import { useRouteMatch } from "react-router-dom"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@mui/material/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import clsx from "clsx"
import { ReactComponent as DropDownArrowIcon } from "../../assets/images/drop_down_arrow.svg"
import { typography } from "../../services/theme/typography"
import { AffinityGroup } from "../../models/affinity-group"
import { CoachGroupsSubItem } from "./coach-groups-sub-item"

const useStyles = makeStyles((theme) =>
  createStyles({
    coachGroupsListItem: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2, 0),
    },
    activeGroupListItem: {
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      "&:hover": {
        background: theme.palette.common.white,
      },
    },
    coachName: {
      ...typography.circularXXMedium,
      fontSize: theme.spacing(2),
      color: theme.palette.common.white,
      textTransform: "none",
      wordBreak: "break-all",
      marginRight: theme.spacing(1),
    },
    activeGroupCoachName: {
      color: "#4070B4",
    },
    downArrow: {
      transform: "rotate(-180deg)",
    },
    listContainer: {
      padding: 0,
    },
    divider: {
      height: 2,
      backgroundColor: "#9FB7D9",
      marginTop: theme.spacing(2),
    },
  }),
)

interface RouteParams {
  personId: string
  groupId: string
  coachId: string
}

interface PilotDrawerCollapsableGroupItemsProps {
  defaultCollapseOpen?: boolean
  coachID?: string
  group: AffinityGroup
}

const PilotDrawerCollapsableGroupItems = ({
  defaultCollapseOpen = false,
  coachID,
  group,
}: PilotDrawerCollapsableGroupItemsProps) => {
  const classes = useStyles()
  const [groupsTabOpen, setGroupsTabOpen] = useState(defaultCollapseOpen)
  const { params } =
    useRouteMatch<RouteParams>(
      "/pilot-trainer/:coachId/group/:groupId/:route",
    ) || {}
  const isGroupSelected = params?.groupId === group.groupID

  return (
    <Box>
      <ListItem
        button
        onClick={() => setGroupsTabOpen(!groupsTabOpen)}
        className={clsx(classes.coachGroupsListItem, {
          [classes.activeGroupListItem]: isGroupSelected,
        })}>
        <ListItemText
          primary={group.groupName}
          style={{ margin: 0 }}
          classes={{
            primary: clsx(classes.coachName, {
              [classes.activeGroupCoachName]: isGroupSelected,
            }),
          }}
        />

        {groupsTabOpen ? (
          <DropDownArrowIcon className={classes.downArrow} stroke="#fff" />
        ) : (
          <DropDownArrowIcon stroke="#fff" />
        )}
      </ListItem>

      <Collapse in={groupsTabOpen} timeout="auto">
        <List className={classes.listContainer}>
          <CoachGroupsSubItem
            key={group.groupID}
            coachID={coachID}
            group={group}
          />
        </List>
      </Collapse>

      <Divider orientation="horizontal" className={classes.divider} />
    </Box>
  )
}
export { PilotDrawerCollapsableGroupItems }
