import React, { useState, useEffect, MouseEvent, FC } from "react"
import { observer } from "mobx-react-lite"
import { Box, Typography, Avatar, IconButton } from "@material-ui/core"
import MenuIcon from "@mui/icons-material/Menu"
import { useStores } from "../../models/root-store"
import { useLogout } from "../../utils/hooks/useLogout"
import { useSnackbars } from "../../components/use-snackbar"
import { Status, StatusToggle } from "./status-toggle"
import { ProfilePopover } from "./profile-popover"
import { useStyles } from "./useStyles"

const ProfileMenu: FC = observer(() => {
  const classes = useStyles()
  const { loginStore, pilotTrainerStore } = useStores()
  const { person } = loginStore
  const { coachIsOnline, apiUpdateCoachStatus } = pilotTrainerStore
  const { handleLogOut } = useLogout()

  const [profileMenuEl, setProfileMenuEl] = useState<HTMLElement | null>(null)
  const [status, setStatus] = useState<Status>(
    coachIsOnline ? Status.Online : Status.Offline,
  )

  const { setAndShowSnackbar } = useSnackbars()

  const openProfileMenu = (event: MouseEvent<HTMLElement>) =>
    setProfileMenuEl(event.currentTarget)

  const closeProfileMenu = () => setProfileMenuEl(null)

  const handleStatusChange = async (_: MouseEvent<HTMLElement>) => {
    const newStatus = status === Status.Online ? Status.Offline : Status.Online
    const isOnline = newStatus === Status.Online
    try {
      setStatus(newStatus)
      await apiUpdateCoachStatus(isOnline)

      setAndShowSnackbar({
        text: `Coach status changed successfully to ${newStatus}!`,
        severity: "success",
      })
    } catch (error) {
      setAndShowSnackbar({
        text: "Coach status failed to change!",
        severity: "error",
      })
    }
  }

  useEffect(() => {
    setStatus(coachIsOnline ? Status.Online : Status.Offline)
  }, [coachIsOnline])

  return (
    <Box className={classes.profileBox}>
      <Typography className={classes.coachName}>
        {person.firstName} {person.lastName}
      </Typography>
      <StatusToggle status={status} onChange={handleStatusChange} />

      <Avatar
        variant="circular"
        src={person.profile.avatarImageURL}
        alt="Avatar"
        className={classes.avatar}
      />

      <IconButton
        color="inherit"
        className={classes.iconButton}
        onClick={openProfileMenu}>
        <MenuIcon className={classes.hamburgerIcon} />
      </IconButton>

      <ProfilePopover
        anchorEl={profileMenuEl}
        onClose={closeProfileMenu}
        onLogout={handleLogOut}
      />
    </Box>
  )
})

export { ProfileMenu }
