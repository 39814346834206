import { PilotChatMeta, PilotChatMetaSnapshot } from "../pilot-chat-meta"
import { PilotCoachMeta } from "../pilot-coach-meta"
import { Person } from "../person"

export const transformChatMeta = (
  data: PilotChatMeta | PilotChatMetaSnapshot,
) => {
  //transform the participant details data to include the id attribute
  const participantDetails = Object.keys(data.participantDetails).reduce(
    (acc, nextId) => {
      return {
        ...acc,
        [nextId]: {
          id: nextId,
          ...data.participantDetails[nextId],
          //temp remove motivation field until motivations feature is completed
          motivation: "",
        },
      }
    },
    {},
  )
  return { ...data, participantDetails }
}

export const transformParticipantsList = (participants: Person[]) => {
  return participants.map((p) => ({
    id: p.personID,
    ...p,
  }))
}

export const sortCoachGroupsMetaList = (
  groupsMetaList: PilotCoachMeta[],
  currentCoachID: string,
) => {
  return groupsMetaList.slice().sort((a, b) => {
    //move groups meta item to top, if groups are assigned to the logged-in coach
    if (a.coach.personID === currentCoachID) {
      return -1
    }
    if (b.coach.personID === currentCoachID) {
      return 1
    }
    //move groups meta item to the bottom, if the groups list is empty
    if (a.coachGroups.length === 0) {
      return 1
    }
    if (b.coachGroups.length === 0) {
      return -1
    }
    return 0
  })
}
