import React from "react"
import { Stack, Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import moment from "moment"

import { CustomTooltip, ShortText } from "../admin-msl-events-list/short-text"
import { useStores } from "../../models/root-store"
import { DidYouKnowTile } from "../../models/did-you-know-tile"
import { TileStatusAction } from "./tile-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },

    image: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTileModal?: (event: DidYouKnowTile) => void
  tileID?: string
}
const CellComponent = ({
  openEditTileModal,
  tileID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { didYouKnowTiles } = adminMslEventStoreModel

  const tile = didYouKnowTiles.find((tile) => tile.id === tileID)

  switch (props.field) {
    case "imageView":
      return (
        <CustomTooltip title={tile.image}>
          {!!tile.image ? (
            <img src={tile.image} alt="dykTiles" className={classes.image} />
          ) : (
            <span>N/A</span>
          )}
        </CustomTooltip>
      )

    case "active":
      return <TileStatusAction tile={tile} />

    case "createdAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>
    case "updatedAt":
      return <>{props.value ? moment(props.value).format("hh:mm A") : "N/A"}</>

    case "edit":
      return (
        <Stack flex={1} alignItems="center" flexWrap="wrap" spacing={1}>
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={() => openEditTileModal(tile)}>
            Edit
          </Button>
        </Stack>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
