import { Grid, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { AdminTriProgramsList } from "../../components/admin-tri-programs"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  }),
)

const AdminTriPrograms = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <AdminTriProgramsList />
    </Grid>
  )
}

export default AdminTriPrograms
