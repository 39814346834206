import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"
import { Module, ModuleModel } from "../tri-content-modules"

export const TriProgramModel = types
  .model("TriProgram")
  .props({
    id: types.identifier,
    name: types.optional(types.maybeNull(types.string), ""),
    summary: types.optional(types.maybeNull(types.string), ""),
    label: types.optional(types.maybeNull(types.string), ""),
    active: types.optional(types.boolean, false),
    proEnabled: types.optional(types.boolean, false),
    modules: types.optional(types.array(ModuleModel), []),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setSummary(value: string) {
      self.summary = value
    },
    setLabel(value: string) {
      self.label = value
    },
    setIsActive(value: boolean) {
      self.active = value
    },
    setProEnabled(value: boolean) {
      self.proEnabled = value
    },
    setModules(value: Module[]) {
      self.modules.replace(value.map((e) => clone(e)))
    },

    reset() {
      self.id = "-1"
      self.name = ""
      self.summary = ""
      self.label = ""
      self.active = false
    },
  }))
  .views((self) => ({
    get createTriProgramErrors() {
      return validate(CREATE_NEW_TRI_PROGRAM_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TRI_PROGRAM_RULES: ValidationRules = {
  name: {
    presence: { allowEmpty: false, message: "required" },
  },
  summary: {
    presence: { allowEmpty: false, message: "required" },
  },
  label: {
    presence: { allowEmpty: false, message: "required" },
  },
  active: {
    presence: { allowEmpty: false, message: "required" },
  },
  proEnabled: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface TriProgram extends Instance<typeof TriProgramModel> {}
export interface TriProgramSnapshot
  extends SnapshotOut<typeof TriProgramModel> {}
