import React from "react"
import { Link } from "react-router-dom"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import clsx from "clsx"
import { typography } from "../../services/theme/typography"

const useStyles = makeStyles((theme) =>
  createStyles({
    nestedListItem: {
      padding: theme.spacing(1.5),
      paddingRight: 0,
    },
    nestedListName: {
      fontSize: theme.spacing(1.75),
      color: "#ECECEC",
      margin: 0,
      ...typography.circularXXBold,
    },
    nestedListIconContainer: {
      minWidth: 0,
      width: theme.spacing(3),
    },
    selectedListItem: {
      backgroundColor: "rgba(255, 255, 255, 0.2) !important",
      borderRadius: theme.spacing(1),
    },
    selectedTextItem: {
      color: theme.palette.common.white,
    },
    expandedListItem: {
      marginRight: 16,
      flex: "initial",
    },
  }),
)

interface GroupOptionProps {
  selected?: boolean
  to: string
  icon: React.ReactNode
  label: string
  children?: React.ReactNode
}

const GroupOption = ({
  selected,
  to,
  icon,
  label,
  children,
}: GroupOptionProps) => {
  const classes = useStyles()

  return (
    <ListItem
      selected={selected}
      to={to}
      component={Link}
      button
      className={classes.nestedListItem}
      classes={{
        selected: classes.selectedListItem,
      }}>
      <ListItemIcon className={classes.nestedListIconContainer}>
        {icon}
      </ListItemIcon>

      <ListItemText
        classes={{
          root: classes.expandedListItem,
          primary: clsx(classes.nestedListName, {
            [classes.selectedTextItem]: selected,
          }),
        }}
        primary={label}
      />

      {children}
    </ListItem>
  )
}

export { GroupOption }
