import React from "react"
import { observer } from "mobx-react-lite"
import { useRouteMatch, useHistory } from "react-router-dom"
import { Theme, Typography, Stack, IconButton } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"

import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

interface RouteParams {
  affinityGroupId: string
  groupId: string
  coachId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerLabel: {
      fontSize: theme.spacing(3),
      fontFamily: typography.circularXXMedium.fontFamily,
      color: "#485359",
    },
    backButton: {
      padding: 0,
    },
    backIcon: {
      fontSize: `${theme.spacing(5)} !important`,
      color: "#1771A6",
    },
  }),
)

export const HeaderTitle = observer(() => {
  const classes = useStyles()
  const { pilotTrainerStore } = useStores()
  const { groupsNamesMap } = pilotTrainerStore

  const history = useHistory()
  const goBack = () => history.goBack()

  const matchAffinityGroupRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId",
  )
  const matchOneOnOneGroupRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/group/:groupId/:route",
  )
  const matchPersonDetailsRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/group/:groupId/members/:personId",
  )

  const { affinityGroupId } = matchAffinityGroupRoute?.params || {}
  const { groupId } = matchOneOnOneGroupRoute?.params || {}

  if (matchPersonDetailsRoute?.isExact) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton className={classes.backButton} onClick={goBack}>
          <KeyboardBackspaceIcon className={classes.backIcon} />
        </IconButton>

        <Typography className={classes.headerLabel}>Back</Typography>
      </Stack>
    )
  }

  if (affinityGroupId || groupId) {
    return (
      <Typography className={classes.headerLabel}>
        {groupsNamesMap[affinityGroupId || groupId] || ""}
      </Typography>
    )
  }

  return <Typography className={classes.headerLabel}>My Home</Typography>
})
