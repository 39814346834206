import {
  List,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"
import { TriProduct } from "../../models/tri-product"
import { BackupProductItem } from "./backup-product-item"

const useTriNestedProductForm = ({ isModalOpen }: { isModalOpen: boolean }) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const { triProductsPagination } = adminMslEventStoreModel
  const {
    nestedTriProduct,
    apiAdminEditTriProduct,
    apiAdminRemoveBackupProduct,
    resetNestedTriProduct,
  } = triProductsPagination

  const {
    isBackup,
    type,
    backups,
    setType,
    createTriProductErrors: errors,
  } = nestedTriProduct

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNestedTriProduct()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      await apiAdminEditTriProduct({ isNestedProduct: true })
      setAndShowSnackbar({
        text: "Successfully edited product !",
        severity: "success",
      })

      // closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleTypeChange = (
    event: React.SyntheticEvent<Element, Event>,
    _,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any>,
  ) => {
    const { option } = details

    if (reason === "selectOption") {
      setType(option.value)
    }

    if (reason === "removeOption") {
      setType(null)
    }
  }

  const isMainProductWithBackups = !isBackup && backups.length > 0

  const handleRemoveBackup = async (backup: TriProduct) => {
    try {
      await apiAdminRemoveBackupProduct({
        ...backup,
        mainProductId: null,
        backupSequence: null,
        isBackup: false,
      })
      setAndShowSnackbar({
        text: "Successfully removed !",
        severity: "success",
      })
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const triProductFormConfig = [
    {
      fieldName: "id",
      label: "ID",
      showOnlyOnEditMode: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        disabled: true,
      },
    },
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter name",
      },
    },
    {
      fieldName: "displayName",
      onChangeMethod: "setDisplayName",
      label: "Display Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter display name",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Description",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter description",
      },
    },
    {
      fieldName: "upc",
      onChangeMethod: "setUPC",
      label: "UPC",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter upc",
      },
    },
    {
      fieldName: "WMProductID",
      onChangeMethod: "setWmProductID",
      label: "WM Product ID",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter name",
        type: "number",
      },
    },
    {
      fieldName: "imageURL",
      onChangeMethod: "setImageURL",
      label: "Image URL",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter Image URL",
      },
    },
    {
      fieldName: "retailer",
      onChangeMethod: "setRetailer",
      label: "Retailer",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter retailer",
      },
    },
    {
      fieldName: "type",
      label: "Type",
      fieldType: FieldTypes.Select,
      componentProps: {
        disableClearable: true,
        options: [{ label: "Snack", value: "snack" }],
        value: type ? { label: type, value: type } : null,
        renderOption: (props, option: { label: string }) => (
          <li {...props} key={option.label}>
            {option.label}
          </li>
        ),
        getOptionLabel: (option) => {
          return option.label
        },
        onChangeMethod: handleTypeChange,
        Input: {
          placeholder: "Select Type",
        },
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "isBackup",
      onChangeMethod: "setIsBackup",
      label: "Is backup ?",
      fieldType: FieldTypes.Switch,
    },
    {
      ...(isBackup && {
        fieldName: "backupSequence",
        onChangeMethod: "setBackupSequence",
        label: "Backup sequence",
        fieldType: FieldTypes.TextInput,
        required: true,
        componentProps: {
          placeholder: "Enter backup sequence",
          type: "number",
        },
      }),
    },
    {
      ...(isBackup && {
        fieldName: "mainProductId",
        onChangeMethod: "setMainProductID",
        label: "Main product ID",
        required: true,
        fieldType: FieldTypes.TextInput,
        componentProps: {
          disabled: true,
          placeholder: "Enter main product ID",
        },
      }),
    },

    {
      ...(isMainProductWithBackups && {
        fieldName: "back",
        onChangeMethod: "",
        label: "Backup products",
        fieldType: FieldTypes.Select,
        componentProps: {
          id: "backupProductsList",
          disabled: true,
          options: [],
          value: null,
          selectedOptionContent: (
            <List>
              {backups.slice().map((backup: TriProduct) => (
                <BackupProductItem
                  backup={backup}
                  handleRemoveBackup={() => handleRemoveBackup(backup)}
                />
              ))}
            </List>
          ),
        },
        showOnlyOnEditMode: true,
      }),
    },
  ]

  return {
    nestedTriProduct,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: nestedTriProduct,
    errors,
    triProductFormConfig,
  }
}

export { useTriNestedProductForm }
