import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"
import { UploadFileModel, UploadFile } from "../upload-file"

export const RakCardModel = types
  .model("RakCard")
  .props({
    id: types.identifier,
    imageURL: types.optional(types.maybeNull(types.string), ""),
    friendlyName: types.optional(types.maybeNull(types.string), ""),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
    active: types.optional(types.maybeNull(types.boolean), false),
    sequence: types.maybeNull(types.number),
    uploadFiles: types.optional(
      types.model({
        imageURL: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "imageURL",
          }),
        ),
      }),
      {},
    ),
  })
  .actions((self) => ({
    setFriendlyName(value: string) {
      self.friendlyName = value
    },
    setImageURL(value: string) {
      self.imageURL = value
    },
    setActive(value: boolean) {
      self.active = value
    },
    setSequence(value: number | null) {
      self.sequence = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },
    setContentUrl(id: "imageURL", value: string) {
      self[id] = value
    },

    reset() {
      self.imageURL = ""
      self.active = false
      self.friendlyName = ""
      self.uploadFiles = {
        imageURL: null,
      }
    },
  }))
  .views((self) => ({
    get createRakCardErrors() {
      return validate(CREATE_NEW_RAK_CARD_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
    get formattedCreatedAt() {
      return !!self.createdAt ? moment(self.createdAt).format("lll") : null
    },
    get formattedUpdatedAt() {
      return !!self.updatedAt ? moment(self.updatedAt).format("lll") : null
    },
  }))

const CREATE_NEW_RAK_CARD_RULES: ValidationRules = {
  imageURL: {
    fileUpload: {},
  },
  friendlyName: {
    presence: { allowEmpty: false, message: "required" },
  },
  active: {
    presence: { allowEmpty: false, message: "required" },
  },
  sequence: {
    validateSequence: {},
  },
}

export interface RakCard extends Instance<typeof RakCardModel> {}
export interface RakCardSnapshot extends SnapshotOut<typeof RakCardModel> {}
