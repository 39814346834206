import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { PersonModel } from "../../models/person"
import { ChatMessageModel, ChatMessage } from "../chat-message"

export const PilotChatMetaModel = types
  .model("PilotChatMeta")
  .props({
    newMessages: types.optional(types.map(types.number), {}),
    participantDetails: types.optional(types.map(PersonModel), {}),
    lastMessage: types.optional(
      types.map(types.maybeNull(ChatMessageModel)),
      {},
    ),
  })
  .actions((self) => ({
    replaceLastMessage(coachID: string, value: ChatMessage) {
      self.lastMessage.set(coachID, value)
    },
    resetParticipantsNewMessages(participantID: string) {
      self.newMessages.set(participantID, 0)
    },
    reset() {
      self.newMessages.clear()
      self.participantDetails.clear()
    },
  }))

export interface PilotChatMeta extends Instance<typeof PilotChatMetaModel> {}
export interface PilotChatMetaSnapshot
  extends SnapshotOut<typeof PilotChatMetaModel> {}
