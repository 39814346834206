import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import {
  GridColDef,
  GridCellProps,
  GridSortModel,
  GridFilterModel,
} from "@mui/x-data-grid"
import { Theme, Stack, Button, Typography } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"
import { useGridApiRef } from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"
import { MslEvent } from "../../models/msl-event"
import { useSnackbars } from "../use-snackbar"
import { noop, copyToClipboard } from "../../utils"
import { typography } from "../../services/theme/typography"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CreateMslEventModal, ModalMode } from "./create-msl-event-modal"
import { CellComponent } from "./cell-component"
import SearchMslEventDropdown from "./search-msl-event-dropdown"
// import {
//   dateColumnSortMethod,
//   trainerColumnFilterMethod,
//   trainerColumnSortMethod,
//   topicColumnFilterMethod,
//   topicColumnSortMethod,
// } from "./utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },
  }),
)

const AdminMslEventList = observer(() => {
  const classes = useStyles()
  const apiRef = useGridApiRef()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateEventModalOpen, toggleCreateEventModalOpen] = useState(false)
  const [mslEventModalMode, setMslEventModalMode] = useState<ModalMode>(
    ModalMode.ADD_NEW_EVENT,
  )

  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    mslEventsPagination,
    trainersMap,
    setEditMslEvent,
    resetNewMslEvent,
    apiAdminGetMslEvents,
    apiAdminGetMindsetTrainers,
    apiAdminGetEventTopics,
    apiAdminGetCategories,
    apiAdminGetTags,
  } = adminMslEventStoreModel
  const {
    mslEvents,
    totalCount,
    pageSize: currentPageSize,
    page: currentPage,
    loading,
    filtered,
    showSearchResults,
    setLoading,
    setPage,
    setPageSize,
    setSortBy,
    setOrderBy,
    setFiltered,
    resetPagination,
  } = mslEventsPagination

  const openCreateEventModal = () => {
    resetNewMslEvent()
    setMslEventModalMode(ModalMode.ADD_NEW_EVENT)
    toggleCreateEventModalOpen(true)
  }

  const closeCreateEventModal = () => {
    toggleCreateEventModalOpen(false)
  }

  const openEditEventModal = useCallback((event: MslEvent) => {
    setEditMslEvent(event)
    setMslEventModalMode(ModalMode.EDIT_EVENT)
    toggleCreateEventModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopyToClipboard = useCallback((value: string, label: string) => {
    copyToClipboard(value)
    setSnackbarSeverity("success")
    setSnackbarText(`Successfully copied to clipboard ${label}!`)
    setShowSnackbar(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaginationChange = async ({ page, pageSize }) => {
    if (currentPageSize !== pageSize) {
      setLoading(true)
      resetPagination()
      setPageSize(pageSize)
      await apiAdminGetMslEvents()
      return
    }

    if (currentPage < page + 1) {
      setLoading(true)
      setPage(currentPage + 1)
      await apiAdminGetMslEvents()
    }
  }

  const restorePaginationState = () => {
    const state = apiRef.current.exportState()
    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
          pageSize: currentPageSize,
        },
      },
    }
    apiRef.current.restoreState(restoredState)
  }

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      const sortOption = sortModel[0]

      if (!sortOption) {
        resetPagination()
        apiAdminGetMslEvents()
        return
      }
      resetPagination()
      restorePaginationState()
      setSortBy(sortOption.field)
      setOrderBy(sortOption.sort)
      apiAdminGetMslEvents()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onFilterChange = useCallback(
    async (filterModel: GridFilterModel) => {
      const filterOption = filterModel?.items?.[0]?.value
      const isEmptyFilter = !filterOption
      const isFilterReset = typeof filtered === "boolean"
      if (isEmptyFilter) {
        if (isFilterReset) {
          setLoading(true)
          await new Promise((resolve) => setTimeout(resolve, 100))
          resetPagination()
          apiAdminGetMslEvents()
        }
        return
      }

      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))
      resetPagination()
      setFiltered(filterOption === "true")
      apiAdminGetMslEvents()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtered],
  )

  useEffect(() => {
    const initialFetch = async () => {
      setLoading(true)
      resetPagination()
      await apiAdminGetMslEvents()
      toggleFirstLoading(false)
      await apiAdminGetMindsetTrainers()
      await apiAdminGetEventTopics()
      await apiAdminGetCategories()
      await apiAdminGetTags()
    }
    initialFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiAdminGetMslEvents,
    apiAdminGetMindsetTrainers,
    apiAdminGetEventTopics,
    apiAdminGetCategories,
    apiAdminGetTags,
  ])

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props}>
        <CellComponent
          {...props}
          handleCopyToClipboard={handleCopyToClipboard}
          openEditEventModal={openEditEventModal}
          trainersMap={trainersMap}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      filterable: false,
    },
    {
      field: "legacyID",
      headerName: "Legacy ID",
      sortable: false,
      filterable: false,
    },
    {
      field: "trainerIDV2",
      headerName: "Trainer",
      sortable: false,
      filterable: false,
      // sortComparator: (v1: string, v2: string) =>
      //   trainerColumnSortMethod(v1, v2, trainersMap),
      // filterOperators: trainerColumnFilterMethod(trainersMap),
    },
    {
      field: "name",
      headerName: "Name",
      sortable: !showSearchResults,
      filterable: false,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      filterable: false,
    },
    {
      field: "isPublished",
      headerName: "Publish Event",
      type: "boolean",
      sortable: false,
      filterable: !showSearchResults,
    },
    {
      field: "formattedPublishStartTime",
      headerName: "Publish Start Time",
      sortable: !showSearchResults,
      filterable: false,
    },
    {
      field: "formattedPublishEndTime",
      headerName: "Publish End Time",
      sortable: false,
      filterable: false,
    },
    {
      field: "isFeatured",
      headerName: "Is Featured?",
      type: "boolean",
      sortable: false,
      filterable: false,
    },
    {
      field: "posterImageURL",
      headerName: "Poster",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "topic",
      headerName: "Topic",
      sortable: false,
      filterable: false,
      // sortComparator: topicColumnSortMethod,
      // filterOperators: topicColumnFilterMethod,
    },
  ]

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>MSL Events</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateEventModal}>
          Add event
        </Button>
      </Stack>

      <SearchMslEventDropdown />

      <CustomGridTable
        apiRef={apiRef}
        rows={loading ? [] : mslEvents.slice()}
        columns={columns}
        aria-label="msl-events-table"
        cell={MemoTableCell}
        onFilterModelChange={onFilterChange}
        emptyStateLabel="When you create some events, they will appear here"
        loading={loading || firstLoading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: currentPageSize,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        {...(showSearchResults
          ? {
              pageSizeOptions: [20],
            }
          : {
              rowCount: totalCount,
              onPaginationModelChange: handlePaginationChange,
              onSortModelChange: handleSortModelChange,
              initialState: {
                pagination: {
                  paginationModel: {
                    pageSize: currentPageSize,
                  },
                },
              },
              pageSizeOptions: [20, 50, 100],
            })}
      />

      <CreateMslEventModal
        open={isCreateEventModalOpen}
        closeModal={closeCreateEventModal}
        mode={mslEventModalMode}
      />
    </div>
  )
})

export { AdminMslEventList }
