export const CREATED_AT = "created_at"
export const NAME = "name"
export const PUBLISH_START_TIME = "publish_start_time"
export type SORT_BY_TYPE =
  | typeof CREATED_AT
  | typeof NAME
  | typeof PUBLISH_START_TIME

export const ASCENDING = "asc"
export const DESCENDING = "desc"
export type ORDER_BY_TYPE = typeof ASCENDING | typeof DESCENDING
