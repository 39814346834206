import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-did-you-know-tile-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

const useDYKTilesForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newDidYouKnowTile,
    apiAdminDYKTilesUploadContent,
    apiAdminCreateDidYouKnowTile,
    apiAdminEditDidYouKnowTile,
    resetNewDidYouKnowTile,
  } = adminMslEventStoreModel

  const {
    uploadFiles,
    setUploadFile,
    createDidYouKnowTileErrors: errors,
  } = newDidYouKnowTile

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewDidYouKnowTile()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      await apiAdminDYKTilesUploadContent()
      if (mode === ModalMode.ADD_NEW_TILE) {
        await apiAdminCreateDidYouKnowTile()
        setAndShowSnackbar({
          text: "Successfully created tile !",
          severity: "success",
        })
      } else {
        await apiAdminEditDidYouKnowTile()
        setAndShowSnackbar({
          text: "Successfully edited tile !",
          severity: "success",
        })
      }

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "friendlyName",
      onChangeMethod: "setFriendlyName",
      label: "Friendly Name",
      fieldType: FieldTypes.TextInput,
      required: true,
      componentProps: {
        placeholder: "Enter friendly name",
      },
    },
    {
      fieldName: "image",
      onChangeMethod: "setImage",
      label: "Image URL",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter image URL or select locally",
        filePickerProps: {
          label: "Select image",
          fileType: "dyk_tile_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.image,
        },
      },
    },
    {
      fieldName: "fact",
      onChangeMethod: "setFact",
      label: "Fact",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter facts here",
      },
    },
    {
      fieldName: "source",
      onChangeMethod: "setSource",
      label: "Enter source here",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter source here",
      },
    },
    {
      fieldName: "active",
      onChangeMethod: "setIsActive",
      label: "Is active ?",
      fieldType: FieldTypes.Switch,
    },
  ]

  return {
    loading,
    submitted,
    values: newDidYouKnowTile,
    errors,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useDYKTilesForm }
