import React from "react"
import { Theme, Typography, Stack, Button } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { ChatMessage } from "../../models/chat-message"
import { getMessageTimeLabel } from "../../utils"
import { Linky } from "../linky"
import { typography } from "../../services/theme/typography"
import { ReactComponent as DeleteSessionIcon } from "../../assets/images/delete_session_icon.svg"

interface StyleProps {
  isMessageFromCoach: boolean
  isFlaggedMessage: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    personName: {
      marginBottom: theme.spacing(0.5),
      fontSize: theme.spacing(2),
      color: "#2E57A1",
      ...typography.circularXXBold,
    },
    bubble: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2.5, 3.5, 1.5, 3),
      borderRadius: theme.spacing(3.75),
      border: (props) => {
        if (props.isMessageFromCoach) {
          return "none"
        }
        return `1px solid ${props.isFlaggedMessage ? "#F23D3C" : "#1771A6"}`
      },
      backgroundColor: (props) => {
        if (props.isMessageFromCoach) {
          return "#F8FAFA"
        }
        return theme.palette.common.white
      },
      maxWidth: "600px",
    },
    message: {
      whiteSpace: "pre-wrap",
      color: (props) => {
        if (props.isMessageFromCoach) {
          return "#677278"
        }
        return theme.palette.text.secondary
      },
      fontSize: 16,
      lineHeight: theme.spacing(3),
      ...typography.circularXXRegular,
    },
    bubbleActionRow: {
      marginTop: theme.spacing(1.5),
      justifyContent: "flex-end",
      alignItems: "center",
    },
    createdAt: {
      display: "block",
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(2),
      color: (props) => {
        if (props.isMessageFromCoach) {
          return "#888C96"
        }
        return theme.palette.text.secondary
      },
      ...typography.circularXXRegular,
    },
    "@keyframes fadeInUp": {
      from: {
        transform: "translate3d(0,40px,0)",
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: 1,
      },
    },
    linky: {
      color: `-webkit-link !important`,
      textDecoration: "underline",
    },
    deleteButton: {
      minWidth: 0,
      padding: 0,
      alignSelf: "flex-end",
    },
    deleteIcon: {
      width: 18,
      height: 20,
      fill: "#888C96",
    },
  }),
)

interface ChatBubbleProps {
  message: ChatMessage
  isMessageFromCurrentPerson: boolean
  isMessageFromCoach: boolean
  showAuthorName?: boolean
  onMessageDelete: () => void
  isFlaggedMessage?: boolean
  CustomContent?: React.ReactNode
}

const DefaultChatBubble = ({
  message,
  isMessageFromCurrentPerson,
  isMessageFromCoach,
  showAuthorName,
  onMessageDelete,
  isFlaggedMessage,
  CustomContent,
}: ChatBubbleProps) => {
  const classes = useStyles({ isMessageFromCoach, isFlaggedMessage })

  return (
    <div className={classes.bubble}>
      {showAuthorName && !isMessageFromCoach && (
        <Typography className={classes.personName}>
          {message?.author?.firstNameWithLastInitial}
        </Typography>
      )}

      {CustomContent}

      <Typography variant="body1" className={classes.message}>
        <Linky className={classes.linky} target="_blank">
          {message.message}
        </Linky>
      </Typography>

      <Stack className={classes.bubbleActionRow} direction="row" spacing={1.75}>
        <Typography className={classes.createdAt} variant="caption">
          {getMessageTimeLabel(message.createdAt, message.createdAtFromNow)}
        </Typography>

        {isMessageFromCurrentPerson && (
          <Button className={classes.deleteButton} onClick={onMessageDelete}>
            <DeleteSessionIcon className={classes.deleteIcon} fill="#fff" />
          </Button>
        )}
      </Stack>
    </div>
  )
}

export { DefaultChatBubble }
