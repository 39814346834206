import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import React, { FC, ReactNode } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { PilotAppDrawer, drawerWidth } from "../pilot-app-drawer"
import { PilotAppBar, appBarHeight } from "../pilot-app-bar"
import { AffinityTabsBar } from "../affinity-tabs-bar"

interface PilotLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      flexDirection: "column",
    },
    appContentWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    mainLayout: {
      flexGrow: 1,
      flexDirection: "column",
    },
    tabLayout: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      paddingLeft: drawerWidth,
      paddingTop: appBarHeight,
      background: theme.palette.common.white,
    },
    routes: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflow: "auto",
    },
  }),
)

export const PilotLayout: FC<PilotLayoutProps> = observer(({ children }) => {
  const classes = useStyles()

  return (
    <>
      <main className={clsx(classes.content)}>
        <div className={clsx(classes.appContentWrapper)}>
          <PilotAppDrawer />

          <div className={clsx(classes.mainLayout)}>
            <PilotAppBar />

            <div className={clsx(classes.tabLayout)}>
              <AffinityTabsBar />

              <div className={clsx(classes.routes)}>{children}</div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
})
