import { types, Instance, SnapshotOut } from "mobx-state-tree"

export const UploadFileModel = types
  .model("UploadFile")
  .props({
    id: types.optional(types.string, ""),
    fileType: types.optional(types.string, ""),
    fileName: types.optional(types.string, ""),
    url: types.optional(types.string, ""),
    file: types.optional(types.frozen(), null),
    progress: types.optional(types.number, 0),
    error: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setUploadFileProgress(value: number) {
      self.progress = value
    },
    setUploadFileError(value: string) {
      self.error = value
    },

    reset() {
      self.fileType = ""
      self.fileName = ""
      self.file = null
      self.progress = 0
      self.error = ""
    },
  }))

export interface UploadFile extends Instance<typeof UploadFileModel> {}
export interface UploadFileSnapshot
  extends SnapshotOut<typeof UploadFileModel> {}
