import { useEffect, useState, useCallback, useRef } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { Module } from "../../models/tri-content-modules"
import { typography } from "../../services/theme/typography"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { columns } from "./table-config"
import { ModalMode } from "./create-tri-modules-modal"
import { CreateTriModulesModal } from "./create-tri-modules-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "20% !important",
      maxWidth: "20% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableColumnHeader: {
      width: "20% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const AdminModulesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateTriModuleModalOpen, toggleCreateTriModuleModalOpen] = useState(
    false,
  )
  const [triModuleModalMode, setTriModuleModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )
  const tableRef = useRef()

  const { adminMslEventStoreModel } = useStores()
  const { triContentModules } = adminMslEventStoreModel
  const {
    modules,
    resetModules,
    setNewModule,
    resetNewModule,
    apiAdminGetTriModules,
  } = triContentModules

  useEffect(() => {
    const initialFetch = async () => {
      try {
        resetModules()
        await apiAdminGetTriModules()
      } catch (e) {}
      toggleFirstLoading(false)
    }

    initialFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openCreateTriModuleModal = () => {
    resetNewModule()
    setTriModuleModalMode(ModalMode.CREATE)
    toggleCreateTriModuleModalOpen(true)
  }

  const openEditTriModuleModal = useCallback((event: Module) => {
    setNewModule(event)
    setTriModuleModalMode(ModalMode.EDIT)
    toggleCreateTriModuleModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriModuleModal = () => {
    toggleCreateTriModuleModalOpen(false)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          moduleID={props.rowId as string}
          onEdit={openEditTriModuleModal}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Modules</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTriModuleModal}>
          Add Module
        </Button>
      </Stack>

      <CustomGridTable
        apiRef={tableRef}
        columns={columns}
        aria-label="tri-modules-table"
        cell={MemoTableCell}
        rows={modules.slice()}
        emptyStateLabel="When you create some modules, they will appear here"
        loading={firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
      />

      <CreateTriModulesModal
        open={isCreateTriModuleModalOpen}
        closeModal={closeCreateTriModuleModal}
        mode={triModuleModalMode}
      />
    </div>
  )
})

export { AdminModulesList }
