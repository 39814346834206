import { useCallback } from "react"
import { GridSortModel } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { SORT_TITLE } from "../../models/tri-products-pagination/filter-types"

interface UseTableParams {
  fetchProducts: () => Promise<void>
}

const useTable = ({ fetchProducts }: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()

  const { triProductsPagination } = adminMslEventStoreModel

  const { resetPagination, setLoading, setSortBy } = triProductsPagination

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      const sortOption = sortModel[0]
      if (!sortOption) {
        resetPagination()
        fetchProducts()
        return
      }
      resetPagination()
      setSortBy(SORT_TITLE)
      fetchProducts()
    },
    [fetchProducts, resetPagination, setLoading, setSortBy],
  )

  return {
    handleSortModelChange,
  }
}

export { useTable }
