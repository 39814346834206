import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { AppBar as MaterialAppBar, Toolbar } from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { useStores } from "../../models/root-store"
import { ProfileMenu } from "./profile-menu"

import { drawerWidth } from "../pilot-app-drawer"
import { HeaderTitle } from "./header-title"

interface AppBarProps {}

export const appBarHeight = 96
const appBarWidth = `100vw - ${drawerWidth}px`

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      padding: theme.spacing(0, 3),
      width: `calc(${appBarWidth})`,
      height: appBarHeight,
      justifyContent: "center",
      background: "#FFFFFF",
    },
    adminAppBar: {
      background: "#1771A6",
    },
    toolBar: {
      padding: 0,
      justifyContent: "space-between",
    },
  }),
)

export const PilotAppBar = observer((props: AppBarProps) => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { authentication } = loginStore

  return (
    <>
      <MaterialAppBar
        className={clsx(classes.appBar, {
          [classes.adminAppBar]:
            authentication.isSiteAdmin || authentication.isFtAdmin,
        })}
        position="fixed"
        elevation={0}>
        <Toolbar className={classes.toolBar}>
          <HeaderTitle />

          <ProfileMenu />
        </Toolbar>
      </MaterialAppBar>
    </>
  )
})
