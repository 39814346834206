import { useEffect, useState, useCallback, useRef } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { Theme, Stack, Typography } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { CreateTriProgramModal, ModalMode } from "./create-tri-program-modal"
import { columns } from "./table-config"
import { TriProgram } from "../../models/tri-program"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "17% !important",
      maxWidth: "17% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableColumnHeader: {
      width: "17% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const AdminTriProgramsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [
    isCreateTriProgramModalOpen,
    toggleCreateTriProgramModalOpen,
  ] = useState(false)
  const [triProgramModalMode, setTriProgramModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )
  const tableRef = useRef()

  const { adminMslEventStoreModel } = useStores()
  const { triPrograms, triContentModules } = adminMslEventStoreModel
  const {
    programs,
    setNewTriProgram,
    apiAdminGetTriPrograms,
    apiAdminGetTriProgramModules,
  } = triPrograms

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetTriPrograms()
        await triContentModules.apiAdminGetTriModules()
      } catch (e) {}
      toggleFirstLoading(false)
    }

    initialFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openEditTriProgramModal = useCallback((event: TriProgram) => {
    apiAdminGetTriProgramModules(event.id)
    setNewTriProgram(event)
    setTriProgramModalMode(ModalMode.EDIT)
    toggleCreateTriProgramModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriProgramModal = () => {
    toggleCreateTriProgramModalOpen(false)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTriProgramModal={openEditTriProgramModal}
          triProgramID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Programs</Typography>
      </Stack>

      <CustomGridTable
        apiRef={tableRef}
        columns={columns}
        aria-label="tri-howtos-table"
        cell={MemoTableCell}
        rows={programs.slice()}
        emptyStateLabel="When you create some programs, they will appear here"
        loading={firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
      />

      <CreateTriProgramModal
        open={isCreateTriProgramModalOpen}
        closeModal={closeCreateTriProgramModal}
        mode={triProgramModalMode}
      />
    </div>
  )
})

export { AdminTriProgramsList }
