import React from "react"
import { Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { CustomTooltip, ShortText } from "../admin-msl-events-list/short-text"
import { useStores } from "../../models/root-store"
import { WhatsNextTiles } from "../../models/whats-next-tiles"
import { ActiveSlider } from "./active-slider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },
    image: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditTileModal?: (event: WhatsNextTiles) => void
  tileID?: string
}
const CellComponent = ({
  openEditTileModal,
  tileID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { tiles } = adminMslEventStoreModel

  const tile = tiles.find((tile) => tile.id === tileID)

  switch (props.field) {
    case "imageURL":
      return (
        <CustomTooltip title={tile.imageURL}>
          {!!tile.imageURL ? (
            <img
              src={tile.imageURL}
              alt="whatsNextTiles"
              className={classes.image}
            />
          ) : (
            <span>N/A</span>
          )}
        </CustomTooltip>
      )
    case "active":
      return <ActiveSlider tileID={tileID} />

    case "edit":
      return (
        <Button variant="contained" onClick={() => openEditTileModal(tile)}>
          Edit
        </Button>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
