import React, { useState } from "react"
import {
  Grid,
  Button,
  Popover,
  Theme,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { useSnackbars } from "../../components/use-snackbar"
import { useStores } from "../../models/root-store"
import HelpIcon from "@mui/icons-material/Help"
import { typography } from "../../services/theme/typography"

export const contactSupportSectionHeight = 92

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 1500,
    },
    contactSupportContainer: {
      width: "100%",
      height: contactSupportSectionHeight,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(3, 0),
    },
    popupContent: {
      width: 400,
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    submit: {
      width: "50%",
      marginTop: 8,
    },
    signOutButton: {
      height: theme.spacing(5.5),
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 2),
      background: "#fff",
      textTransform: "none",
      fontSize: 18,
      lineHeight: "22px",
      color: "#424242",
      ...typography.circularXXBook,
    },
    startIcon: {
      marginRight: theme.spacing(2),
      "&> svg:nth-child(1)": {
        fontSize: theme.spacing(3),
        color: "#1771A6",
      },
    },
  }),
)

interface SupportPopupProps {
  showSimpleVersion?: boolean
}

export const SupportPopup = ({ showSimpleVersion }: SupportPopupProps) => {
  const classes = useStyles()
  const { loginStore } = useStores()
  const { contactSupport, authentication } = loginStore
  const isUserAuthenticated = Boolean(authentication.token)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const {
    setSnackbarText,
    setShowSnackbar,
    setSnackbarSeverity,
  } = useSnackbars()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const resetPopup = () => {
    handleClose()
    setEmail("")
    setMessage("")
  }

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    try {
      await contactSupport({ email, message })

      setSnackbarSeverity("success")
      setSnackbarText("Your message was sent successfully !")
      setShowSnackbar(true)

      resetPopup()
    } catch (error) {
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    } finally {
      setLoading(false)
    }
  }

  const open = Boolean(anchorEl)

  return (
    <div className={classes.container}>
      <div className={classes.contactSupportContainer}>
        {showSimpleVersion ? (
          <Button
            className={clsx(classes.signOutButton, classes.startIcon)}
            onClick={handleClick}>
            <HelpIcon />
          </Button>
        ) : (
          <Button
            className={classes.signOutButton}
            classes={{
              startIcon: classes.startIcon,
            }}
            startIcon={<HelpIcon />}
            onClick={handleClick}>
            Get Help
          </Button>
        )}
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: -10,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}>
        <Grid
          onSubmit={submitForm}
          component="form"
          item
          className={classes.popupContent}>
          <Typography sx={{ p: 1 }}>Contact Support</Typography>

          {!isUserAuthenticated && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(evt) => {
                setEmail(evt.target.value)
              }}
              autoFocus
            />
          )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="message"
            label="Message"
            name="message"
            value={message}
            onChange={(evt) => {
              setMessage(evt.target.value)
            }}
            autoFocus
            multiline
            rows={4}
          />

          <Button
            type="submit"
            disabled={loading}
            className={clsx(classes.signOutButton, classes.submit)}>
            {loading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              "Send"
            )}
          </Button>
        </Grid>
      </Popover>
    </div>
  )
}
