import { Theme, CircularProgress } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState, useRef } from "react"
import clsx from "clsx"
import InfiniteScroll from "react-infinite-scroll-component"
import { ChatMessage } from "../../models/chat-message"
import { DirectChatMessagesMeta } from "../../models/direct-chat-messages/direct-chat-messages-meta"
import { useStores } from "../../models/root-store"
import { ChatBubble } from "../chat-bubble/chat-bubble"
import { usePrevious } from "../use-previous"
import { typography } from "../../services/theme/typography"
import { DeleteMessageModal } from "./delete-message-modal"
import { getGroupedMessagesByDate } from "./utils"

interface ChatBubbleGroupProps {
  messages: Array<ChatMessage>
  roomTitle?: string
  showAuthorName?: boolean
  chatMeta?: DirectChatMessagesMeta | null
  isEndReached?: boolean
  isPaginating?: boolean
  isLoading?: boolean
  EmptyComponent?: React.ReactNode
  handleScrollPagination: () => void
  handleDeleteMessage: (messageId: string) => void
}

const ROOM_TITLE_HEIGHT = 68
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: theme.palette.common.white,
    },
    wrapper: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column-reverse",
      padding: theme.spacing(3, 3, 0, 6),
      flex: 1,
    },
    wrapperWithTitle: {
      paddingTop: ROOM_TITLE_HEIGHT,
    },
    roomTitle: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: ROOM_TITLE_HEIGHT,
      position: "absolute",
      borderBottom: "0.5px solid #E0E0E0",
      paddingLeft: theme.spacing(3.5),
      fontSize: theme.spacing(3),
      color: "#212121",
      background: theme.palette.common.white,
      borderTopRightRadius: 14,
      borderTopLeftRadius: 14,
      ...typography.circularXXMedium,
    },
    progressLoader: {
      marginBottom: theme.spacing(2),
      alignSelf: "center",
    },
  }),
)

export const ChatBubbleGroup = observer(
  ({
    messages,
    roomTitle,
    showAuthorName,
    isEndReached,
    isPaginating,
    isLoading,
    EmptyComponent,
    handleScrollPagination,
    handleDeleteMessage,
  }: ChatBubbleGroupProps) => {
    const classes = useStyles()
    const messagesEndRef = useRef(null)
    const { loginStore } = useStores()
    const { person } = loginStore

    const [disableScrollToBottom, setDisableScrollToBottom] = useState<boolean>(
      false,
    )
    const [
      selectedMessageToDelete,
      setSelectedMessageToDelete,
    ] = useState<ChatMessage>()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

    const openDeleteModal = (message: ChatMessage) => {
      setSelectedMessageToDelete(message)
      setIsDeleteModalOpen(true)
    }

    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false)
    }

    const handleDeleteModalClose = (event, reason) => {
      if (reason === "backdropClick") {
        closeDeleteModal()
      }
    }

    const scrollToBottom = () => {
      messagesEndRef?.current?.scrollIntoView?.()
    }

    const previousMessagesCount = usePrevious(messages.length)

    /**
     * Disable scroll to bottom handler while paginating
     */
    useEffect(() => {
      if (isPaginating && !disableScrollToBottom) {
        setDisableScrollToBottom(true)
      }
    }, [isPaginating, disableScrollToBottom])

    /**
     * scroll to bottom if messages length changes
     * e.g: if new messages appears
     */
    useEffect(() => {
      if (!disableScrollToBottom && previousMessagesCount !== messages.length) {
        scrollToBottom()
      } else if (disableScrollToBottom) {
        setDisableScrollToBottom(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, previousMessagesCount, isPaginating])

    const formattedGroupedMessagesList = getGroupedMessagesByDate(messages)

    const _rowRenderer = (message: ChatMessage) => {
      const isMessageFromCurrentPerson = person.id === message.author.id
      const isMessageFromCoach = message?.author?.isCoach

      return (
        <ChatBubble
          key={message.id}
          message={message}
          isMessageFromCurrentPerson={isMessageFromCurrentPerson}
          isMessageFromCoach={isMessageFromCoach}
          showAuthorName={showAuthorName}
          onMessageDelete={() => openDeleteModal(message)}
        />
      )
    }

    if (isLoading) {
      return <CircularProgress />
    }

    if (!!EmptyComponent && messages.length === 0) {
      return <>{EmptyComponent}</>
    }

    return (
      <div className={classes.content}>
        <div
          id="scrollableDiv"
          className={clsx(classes.wrapper, {
            [classes.wrapperWithTitle]: Boolean(roomTitle),
          })}>
          <div ref={messagesEndRef} />

          <InfiniteScroll
            dataLength={formattedGroupedMessagesList.length}
            next={handleScrollPagination}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse={true}
            hasMore={!isEndReached}
            loader={<CircularProgress className={classes.progressLoader} />}
            scrollableTarget="scrollableDiv">
            {formattedGroupedMessagesList.map(_rowRenderer)}
          </InfiniteScroll>
        </div>

        {Boolean(roomTitle) && (
          <div className={classes.roomTitle}>{roomTitle}</div>
        )}

        <DeleteMessageModal
          open={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          closeModal={closeDeleteModal}
          handleDeleteMessage={handleDeleteMessage}
          message={selectedMessageToDelete}
        />
      </div>
    )
  },
)
