import React, { useState } from "react"
import { ListChildComponentProps } from "react-virtualized"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import Stack from "@material-ui/core/Stack"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import AddIcon from "@mui/icons-material/Add"
import { observer } from "mobx-react-lite"

import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { Post } from "../../models/post"
import { MutePostDialog } from "./mute-post-dialog"

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      marginRight: theme.spacing(2),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: "#F5F6F7",
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #C7CFD4",
    },
    alertModeItem: {
      background: "#FDDDDD",
      border: "1px solid #FF0000",
      marginRight: 0,
    },
    focusVisible: {
      background: "transparent",
    },
    userInfoContainer: {
      maxWidth: "50%",
    },
    postInfoContainer: {
      flexWrap: "wrap",
    },
    infoTextRoot: {
      flex: "initial",
    },
    infoText: {
      fontSize: 16,
      lineHeight: "20px",
      "font-weight": "500",
      color: "#2D353B",
    },
    nameText: {
      "font-weight": "900",
    },
    dateText: {
      color: "#B2B9BD",
    },
    gratitudeTagText: {
      color: "#039CAD",
    },
    intentionTagText: {
      color: "#897FDE",
    },
    messageText: {
      fontSize: 16,
      lineHeight: "20px",
      color: "#63646D",
      overflowWrap: "anywhere",
    },
    alertText: {
      color: "#FF0000 !important",
    },
    managePersonActive: {
      color: "#0A8052 !important",
    },
    actionButton: {
      padding: 0,
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#485359",
      },
      "&:active": {
        color: "#2D353B",
      },
    },
    actionButtonText: {
      fontSize: 15,
      lineHeight: "18px",
      color: "#677278",
      fontWeight: "normal",
      "text-transform": "none",
    },
    actionButtonIcon: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  }),
)

type FeedListItemProps = {}

export const FeedListItem = observer(
  ({ index, style }: FeedListItemProps & ListChildComponentProps) => {
    const classes = useStyles()

    const [openMuteDialog, toggleMuteDialog] = useState(false)

    const { adminToolsStore } = useStores()
    const {
      feedSourceList,
      newUserPost,
      apiAdminMutePost,
      apiAdminUnMutePost,
      apiAdminBanUser,
      apiAdminUnBanUser,
      manuallyToggleMutePost,
      manuallyToggleUserBannedOnPosts,
      getPersonData,
      selectedPerson,
      toggleModalPostOpen,
    } = adminToolsStore

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    const post: Post = feedSourceList[index]

    const {
      id,
      personName,
      isAnonymous,
      postType,
      message,
      formattedPostDate,
      formattedPostTime,
      personID,
      isMuted,
      isPersonBanned,
      muteReason,
      createdAt,
      setMuteReason,
    } = post

    const isManagePersonMode = selectedPerson?.person?.id === personID

    const onMuteTogglePress = () => {
      toggleMuteDialog(true)
    }

    const toggleMutePost = async (reason = "") => {
      try {
        //manually toggle muted attribute
        manuallyToggleMutePost({ postID: id })
        if (isMuted) {
          await apiAdminUnMutePost({ postId: id })
        } else {
          await apiAdminMutePost({ postId: id, muteReason: reason })
          setMuteReason(reason)
        }
      } catch (error) {
        //revert toggle if api fails
        manuallyToggleMutePost({ postID: id })
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const editMuteReason = async (reason: string) => {
      try {
        await apiAdminMutePost({ postId: id, muteReason: reason })
        setMuteReason(reason)
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const toggleBanUser = async () => {
      //manually toggle user banned attribute
      manuallyToggleUserBannedOnPosts({ personID })
      try {
        if (isPersonBanned) {
          await apiAdminUnBanUser({ userId: personID })
        } else {
          await apiAdminBanUser({ userId: personID })
        }
      } catch (error) {
        //revert toggle if api fails
        manuallyToggleUserBannedOnPosts({ personID })
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const useAsTemplate = () => {
      newUserPost.usePostAsTemplate({
        personID,
        message,
        isAnonymous,
        postType,
        createdAt,
      })
      toggleModalPostOpen(true)
    }

    return (
      <ListItem style={style} component="div" disablePadding>
        <ListItem
          className={clsx(classes.listItem, {
            [classes.alertModeItem]: isPersonBanned || isMuted,
          })}>
          <Stack spacing={1} className={classes.userInfoContainer}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              className={classes.postInfoContainer}>
              <ListItemText
                primary={personName}
                classes={{
                  root: classes.infoTextRoot,
                  primary: clsx(classes.infoText, classes.nameText, {
                    [classes.alertText]: isPersonBanned,
                  }),
                }}
                style={{ margin: 0 }}
              />
              <Stack direction="row" spacing={2}>
                <ListItemText
                  primary={formattedPostDate}
                  classes={{
                    root: classes.infoTextRoot,
                    primary: clsx(classes.infoText, classes.dateText),
                  }}
                  style={{ margin: 0 }}
                />

                <ListItemText
                  primary={formattedPostTime}
                  classes={{
                    root: classes.infoTextRoot,
                    primary: clsx(classes.infoText, classes.dateText),
                  }}
                />
              </Stack>

              <ListItemText
                primary={`#${postType}`}
                classes={{
                  root: classes.infoTextRoot,
                  primary: clsx(classes.infoText, {
                    [classes.gratitudeTagText]: postType === "gratitude",
                    [classes.intentionTagText]: postType === "intention",
                  }),
                }}
              />
            </Stack>

            <ListItemText
              primary={message}
              classes={{ primary: classes.messageText }}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            flex={1}
            maxWidth={400}>
            <Button
              startIcon={<AddIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: classes.actionButtonText,
                startIcon: classes.actionButtonIcon,
              }}
              onClick={() => useAsTemplate()}>
              Use as template
            </Button>
            <Button
              startIcon={<VisibilityOffOutlinedIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: clsx(classes.actionButtonText, {
                  [classes.alertText]: isMuted,
                }),
                startIcon: classes.actionButtonIcon,
              }}
              onClick={onMuteTogglePress}>
              {isMuted ? "Post Hidden" : "Hide Post"}
            </Button>

            <Button
              startIcon={<NotificationsOffOutlinedIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: clsx(classes.actionButtonText, {
                  [classes.alertText]: isPersonBanned,
                }),
                startIcon: classes.actionButtonIcon,
              }}
              onClick={toggleBanUser}>
              {isPersonBanned ? "Person Muted" : "Mute Person"}
            </Button>

            <Button
              startIcon={<PersonOutlineOutlinedIcon />}
              classes={{
                root: classes.actionButton,
                textPrimary: clsx(classes.actionButtonText, {
                  [classes.managePersonActive]: isManagePersonMode,
                }),
                startIcon: classes.actionButtonIcon,
              }}
              onClick={() => getPersonData({ personID })}>
              Manage Person
            </Button>
          </Stack>
        </ListItem>

        <MutePostDialog
          open={openMuteDialog}
          isMuted={isMuted}
          muteReason={muteReason}
          postMessage={`${personName} - "${message}"`}
          toggleMutePost={toggleMutePost}
          editMuteReason={editMuteReason}
          handleClose={() => toggleMuteDialog(false)}
        />
      </ListItem>
    )
  },
)
