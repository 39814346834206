import { types, Instance, SnapshotOut } from "mobx-state-tree"

import * as customTypes from "../types"

export const AffinityGroupModel = types.model("AffinityGroup").props({
  groupID: types.maybeNull(types.string),
  groupName: types.maybeNull(types.string),
  groupType: types.maybeNull(types.string),
  createdAt: types.maybeNull(customTypes.iso8601),
  nonCoachMembers: types.maybeNull(types.number),
  groupHealthScore: types.maybeNull(types.string),
  appHealthScore: types.maybeNull(types.string),
})

export interface AffinityGroup extends Instance<typeof AffinityGroupModel> {}
export interface AffinityGroupSnapshot
  extends SnapshotOut<typeof AffinityGroupModel> {}
