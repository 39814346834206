import { Grid, Theme } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { AdminTriIdeasList } from "../../components/admin-tri-ideas-list"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  }),
)

const AdminTriHowTos = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <AdminTriIdeasList />
    </Grid>
  )
}

export default AdminTriHowTos
