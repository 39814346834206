import React from "react"
import { Button, Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { CustomTooltip, ShortText } from "../admin-msl-events-list/short-text"
import { useStores } from "../../models/root-store"
import { RakCard } from "../../models/rak-card"
import { ActiveSlider } from "./active-slider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginTop: "10px !important",
    },

    image: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  openEditRakCardModal?: (event: RakCard) => void
  rakCardID?: string
}
const CellComponent = ({
  openEditRakCardModal,
  rakCardID,
  ...props
}: CellComponentProps) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()
  const { rakCards } = adminMslEventStoreModel

  const rakCard = rakCards.find((rakCard) => rakCard.id === rakCardID)

  switch (props.field) {
    case "imageURL":
      return (
        <CustomTooltip title={rakCard.imageURL}>
          {!!rakCard.imageURL ? (
            <img
              src={rakCard.imageURL}
              alt="rak-card"
              className={classes.image}
            />
          ) : (
            <span>N/A</span>
          )}
        </CustomTooltip>
      )

    case "active":
      return <ActiveSlider rakCardID={rakCard.id} />

    case "edit":
      return (
        <Button
          variant="contained"
          onClick={() => openEditRakCardModal(rakCard)}>
          Edit
        </Button>
      )

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
