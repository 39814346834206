import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { TriIdea, TriIdeaModel } from "../tri-idea/tri-idea"
import {
  FILTER_RECIPE,
  FILTER_ACTIVE,
  FILTER_HACK,
  FILTER_ITERATION,
  FILTER_SETUP,
  FILTER_PRODUCT,
  FILTER_VIDEO,
  FILTER_INSIGHT,
  SORT_CREATED_AT,
  SORT_TITLE,
  IDEAS_SORT_TYPE,
  IDEAS_FILTER_TYPE,
} from "./filter-types"

export const LIMIT = 25

/**
 * Represents a pagination model.
 */
export const TriIdeasPaginationModel = types
  .model("TriIdeasPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    query: types.optional(types.maybeNull(types.string), ""),
    loading: types.optional(types.boolean, false),
    next: types.optional(types.maybeNull(types.number), null),
    previous: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    filterBy: types.optional(
      types.union(
        types.literal(FILTER_RECIPE),
        types.literal(FILTER_ACTIVE),
        types.literal(FILTER_HACK),
        types.literal(FILTER_ITERATION),
        types.literal(FILTER_SETUP),
        types.literal(FILTER_PRODUCT),
        types.literal(FILTER_VIDEO),
        types.literal(FILTER_INSIGHT),
      ),
      FILTER_ACTIVE,
    ),
    sortBy: types.optional(
      types.union(types.literal(SORT_CREATED_AT), types.literal(SORT_TITLE)),
      SORT_CREATED_AT,
    ),
    totalCount: types.optional(types.number, 0),
    ideas: types.optional(types.array(TriIdeaModel), []),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setNext(value: number | null) {
      self.next = value
    },
    setPrevious(value: number | null) {
      self.previous = value
    },
    setLimit(value: string | null) {
      self.limit = Number(value)
    },
    setQuery(value: string) {
      self.query = value
    },
    setFilterBy(value: IDEAS_FILTER_TYPE) {
      self.filterBy = value
    },
    setSortBy(value: IDEAS_SORT_TYPE) {
      self.sortBy = value
    },
    setTotalCount(value: number) {
      self.totalCount = value
    },

    setPagination(value: {
      next: number
      previous: number
      limit: number
      filterBy: IDEAS_FILTER_TYPE
      sortBy: IDEAS_SORT_TYPE
      totalCount?: number
    }) {
      self.next = value.next
      self.previous = value.previous
      self.limit = value.limit
      self.filterBy = value.filterBy
      self.sortBy = value.sortBy
      self.totalCount = value.totalCount
    },

    setTriIdeas(value: TriIdea[]) {
      self.ideas.replace(value)
    },
    setSearchTriIdeas(value: TriIdea[]) {
      self.ideas.replace(value)
    },

    updateTriIdea(value: TriIdea) {
      const updatedTriIdeasList = replaceObjectById(self.ideas, value)
      self.ideas.replace(updatedTriIdeasList as TriIdea[])
    },

    deleteTriIdea(value: string) {
      const updatedTriIdeasList = removeObjectById(self.ideas, value)
      self.ideas.replace(updatedTriIdeasList as TriIdea[])
      self.totalCount = self.totalCount - 1
    },

    resetTriIdeas() {
      if (isAlive(self.ideas)) {
        self.ideas.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriIdeas() {
      const { api } = self.environment
      const {
        next,
        limit,
        sortBy,
        filterBy,
        setTriIdeas,
        setLoading,
        setPagination,
      } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriIdeas({
        next,
        limit,
        sortBy,
        filterBy,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const triIdeasPagination = {
        next: data.next,
        previous: data.previous,
        limit: data.limit,
        sortBy: data.sortBy,
        filterBy: data.filterBy,
        totalCount: data.totalCount,
      }

      setTriIdeas(data.ideas)
      setPagination(triIdeasPagination)
      setLoading(false)
    },

    async apiAdminGetTriIdeasSearch() {
      const { api } = self.environment
      const {
        query,
        sortBy,
        filterBy,
        limit,
        setLoading,
        setSearchTriIdeas,
      } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriIdeasByQuery({
        query,
        sortBy,
        filterBy,
        limit,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      setSearchTriIdeas(data.ideas)
      setLoading(false)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriIdeas()
      self.limit = LIMIT
      self.loading = false
      self.totalCount = 0
      self.next = null
      self.previous = null
      self.filterBy = FILTER_RECIPE
      self.sortBy = SORT_CREATED_AT
      self.query = ""
    },
  }))

export interface TriIdeasPagination
  extends Instance<typeof TriIdeasPaginationModel> {}
export interface TriIdeasPaginationSnapshot
  extends SnapshotOut<typeof TriIdeasPaginationModel> {}
