import { Instance, SnapshotOut, types, isAlive, clone } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"
import { uniqBy, prop } from "ramda"

import { replaceObjectById } from "../../utils/replace-object-in-array"
import { removeObjectById } from "../../utils/remove-object-from-array"
import { TriBehavior, TriBehaviorModel } from "../tri-behavior/tri-behavior"

export const LIMIT = 25
export const SORT_TITLE = "title"
export const SORT_CREATED_AT = "createdAt"

export type BEHAVIORS_SORT_TYPE = typeof SORT_TITLE | typeof SORT_CREATED_AT

/**
 * Represents a pagination model.
 */
export const TriBehaviorsPaginationModel = types
  .model("TriBehaviorsPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    loading: types.optional(types.boolean, false),
    next: types.optional(types.maybeNull(types.number), null),
    previous: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    query: types.optional(types.maybeNull(types.string), ""),
    sortBy: types.optional(
      types.union(types.literal(SORT_CREATED_AT), types.literal(SORT_TITLE)),
      SORT_CREATED_AT,
    ),
    totalCount: types.optional(types.number, 0),
    behaviors: types.optional(types.array(TriBehaviorModel), []),
    newTriBehavior: types.optional(TriBehaviorModel, () =>
      TriBehaviorModel.create({
        id: "-1",
      }),
    ),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setQuery(value: string) {
      self.query = value
    },

    setNext(value: number | null) {
      self.next = value
    },
    setPrevious(value: number | null) {
      self.previous = value
    },
    setLimit(value: number | null) {
      self.limit = value
    },
    setSortBy(value: BEHAVIORS_SORT_TYPE) {
      self.sortBy = value
    },
    setTotalCount(value: number) {
      self.totalCount = value
    },
    setPagination(value: {
      next: number
      previous: number
      limit: number
      sortBy: BEHAVIORS_SORT_TYPE
      totalCount?: number
    }) {
      self.next = value.next
      self.previous = value.previous
      self.limit = value.limit
      self.sortBy = value.sortBy
      self.totalCount = value.totalCount
    },

    setTriBehaviors(value: TriBehavior[]) {
      self.behaviors.replace(uniqBy(prop("id"), [...self.behaviors, ...value]))
    },

    updateTriBehavior(value: TriBehavior) {
      const updatedTriBehaviorsList = replaceObjectById(self.behaviors, value)
      self.behaviors.replace(updatedTriBehaviorsList as TriBehavior[])
    },

    deleteTriBehavior(value: string) {
      const updatedTriBehaviorsList = removeObjectById(self.behaviors, value)
      self.behaviors.replace(updatedTriBehaviorsList as TriBehavior[])
      self.totalCount = self.totalCount - 1
    },
    setNewTriBehavior(value: TriBehavior) {
      self.newTriBehavior = clone(value)
    },
    resetTriBehaviors() {
      if (isAlive(self.behaviors)) {
        self.behaviors.clear()
      }
    },
    resetNewTriBehavior() {
      self.newTriBehavior = TriBehaviorModel.create({
        id: "-1",
      })
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriBehaviors() {
      const { api } = self.environment
      const { next, limit, sortBy, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriBehaviors({
        next,
        limit,
        sortBy,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      const triBehaviorsPagination = {
        next: data.next,
        previous: data.previous,
        limit: data.limit,
        sortBy: data.sortBy,
        filterBy: data.filterBy,
        totalCount: data.totalCount,
      }

      self.setTriBehaviors(data.behaviors)
      self.setPagination(triBehaviorsPagination)
      setLoading(false)
    },
    async apiAdminGetTriBehaviorsSearch() {
      const { api } = self.environment
      const { query, limit, sortBy, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriBehaviorsSearch({
        query,
        limit,
        sortBy,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setTriBehaviors(data.behaviors)
      setLoading(false)
    },

    async apiAdminGetSingleTriBehavior(behaviorID: string) {
      const { api } = self.environment
      const { kind, data } = await api.adminGetSingleTriBehavior({ behaviorID })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriBehavior(data.data as TriBehavior)
    },
  }))
  .actions((self) => ({
    async apiAdminCreateTriBehavior(): Promise<void> {
      const { api } = self.environment

      const {
        id,
        createdAt,
        updatedAt,
        ...newBehaviorParams
      } = self.newTriBehavior

      const { data, kind } = await api.adminCreateTriBehavior(newBehaviorParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setNext(0)
      self.apiAdminGetTriBehaviors()
      self.resetNewTriBehavior()
    },

    async apiAdminEditTriBehavior(): Promise<void> {
      const { api } = self.environment

      const { createdAt, updatedAt, ...updateParams } = self.newTriBehavior
      const { data, kind } = await api.adminEditTriBehavior(updateParams)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.updateTriBehavior(data)
      self.resetNewTriBehavior()
    },

    async apiAdminDeleteTriBehavior(behaviorID: string): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriBehavior({
        behaviorID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.deleteTriBehavior(behaviorID)
    },

    async apiAdminAddTagToTriBehavior(behaviorID: string, tagID: string) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddTagToTriBehavior({
        behaviorID,
        tagID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriBehavior(behaviorID)
    },

    async apiAdminDeleteTriBehaviorTag(
      behaviorID: string,
      tagID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriBehaviorTag({
        behaviorID,
        tagID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriBehavior(behaviorID)
    },

    async apiAdminAddIdeaToTriBehavior(behaviorID: string, ideaID: string) {
      const { api } = self.environment

      const { kind, data } = await api.adminAddIdeaToTriBehavior({
        ideaID,
        behaviorID,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriBehavior(behaviorID)
    },

    async apiAdminDeleteTriBehaviorIdea(
      behaviorID: string,
      ideaID: string,
    ): Promise<void> {
      const { api } = self.environment

      const { data, kind } = await api.adminDeleteTriBehaviorIdea({
        ideaID,
        behaviorID,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.apiAdminGetSingleTriBehavior(behaviorID)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriBehaviors()
      self.limit = LIMIT
      self.loading = false
      self.totalCount = 0
      self.next = null
      self.previous = null
      self.sortBy = SORT_CREATED_AT
    },
  }))

export interface TriBehaviorsPagination
  extends Instance<typeof TriBehaviorsPaginationModel> {}
export interface TriBehaviorsPaginationSnapshot
  extends SnapshotOut<typeof TriBehaviorsPaginationModel> {}
