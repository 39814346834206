import React from "react"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Stack from "@material-ui/core/Stack"
import { useRouteMatch } from "react-router-dom"

import { AffinityGroup } from "../../models/affinity-group"
import { ReactComponent as UnreadMessagesIcon } from "../../assets/images/unread_messages_icon.svg"
import { ReactComponent as ProactiveIcon } from "../../assets/images/proactive_icon.svg"
import { ReactComponent as FlaggedIcon } from "../../assets/images/flagged_icon.svg"
import { ReactComponent as SearchMembersIcon } from "../../assets/images/search_members_icon.svg"
import { GroupOption } from "./group-option"

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    nestedBadgeContainer: {
      minWidth: 40,
      display: "flex",
      marginLeft: -8,
    },
    iconStyle: {
      fontSize: 16,
      color: "#ECECEC",
    },
  }),
)

interface RouteMatchParams {
  coachId: string
  groupId: string
}

interface CoachGroupsSubItemProps {
  coachID: string
  group: AffinityGroup
}

const CoachGroupsSubItem = ({ coachID, group }: CoachGroupsSubItemProps) => {
  const classes = useStyles()

  const matchProactiveRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/proactive",
  )
  const matchMemberDetailsRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/members/:personId",
  )
  const matchUnreadMessagesRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/unread-messages",
  )
  const matchChatRoomRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/chat/:roomId",
  )
  const matchFlaggedMessagesRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/flagged-messages",
  )
  const matchFlaggedMessagesConversationRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/flagged-messages/:roomId",
  )

  const matchMemberSearchRoute = useRouteMatch<RouteMatchParams>(
    "/pilot-trainer/:coachId/group/:groupId/member-search",
  )

  const { params } =
    matchProactiveRoute ||
    matchUnreadMessagesRoute ||
    matchChatRoomRoute ||
    matchMemberDetailsRoute ||
    matchFlaggedMessagesRoute ||
    matchMemberSearchRoute ||
    {}

  const isProactiveActive = matchProactiveRoute?.isExact
  const isMatchMemberDetailsRoute = matchMemberDetailsRoute?.isExact
  const isUnreadMessagesActive = matchUnreadMessagesRoute?.isExact
  const isChatConversationActive = matchChatRoomRoute?.isExact
  const isFlaggedMessagesActive = matchFlaggedMessagesRoute?.isExact
  const isFlaggedMessagesConversationRoute =
    matchFlaggedMessagesConversationRoute?.isExact
  const isMemberSearchActive = matchMemberSearchRoute?.isExact

  const isCurrentGroupSelected =
    params?.coachId === coachID && params?.groupId === group.groupID

  return (
    <Stack spacing={1} className={classes.container}>
      <GroupOption
        selected={
          (isUnreadMessagesActive || isChatConversationActive) &&
          isCurrentGroupSelected
        }
        to={`/pilot-trainer/${coachID}/group/${group.groupID}/unread-messages`}
        icon={<UnreadMessagesIcon />}
        label="Unread messages"
      />

      <GroupOption
        selected={
          (isProactiveActive || isMatchMemberDetailsRoute) &&
          isCurrentGroupSelected
        }
        to={`/pilot-trainer/${coachID}/group/${group.groupID}/proactive`}
        icon={<ProactiveIcon />}
        label="Proactive outreach"
      />

      <GroupOption
        selected={
          (isFlaggedMessagesActive || isFlaggedMessagesConversationRoute) &&
          isCurrentGroupSelected
        }
        to={`/pilot-trainer/${coachID}/group/${group.groupID}/flagged-messages`}
        icon={<FlaggedIcon />}
        label="Flagged messages"
      />

      <GroupOption
        selected={isMemberSearchActive && isCurrentGroupSelected}
        to={`/pilot-trainer/${coachID}/group/${group.groupID}/member-search`}
        icon={<SearchMembersIcon />}
        label="Member search"
      />
    </Stack>
  )
}

export { CoachGroupsSubItem }
