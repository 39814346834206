import React from "react"
import { Theme } from "@material-ui/core"
import { GridCellProps } from "@mui/x-data-grid"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"

import { Module } from "../../models/tri-content-modules"
import { ShortText } from "../admin-msl-events-list/short-text"
import { TriModulesActions } from "./tri-modules-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "100%",
      maxWidth: 100,
    },
    largeImage: {
      width: "100%",
      maxWidth: 200,
    },
  }),
)

interface CellComponentProps extends GridCellProps {
  onEdit?: (event: Module) => void
  moduleID?: string
}
const CellComponent = ({ onEdit, moduleID, ...props }: CellComponentProps) => {
  const classes = useStyles()

  switch (props.field) {
    case "iconURI":
      return (
        <>
          {!!props.value ? (
            <img src={props.value} alt="icon URI" className={classes.image} />
          ) : (
            "N/A"
          )}
        </>
      )

    case "iconURILarge":
      return (
        <>
          {!!props.value ? (
            <img
              src={props.value}
              alt="icon URI Large"
              className={classes.largeImage}
            />
          ) : (
            "N/A"
          )}
        </>
      )
    case "actions":
      return <TriModulesActions moduleID={moduleID} onEdit={onEdit} />

    default:
      return <ShortText value={props.value} />
  }
}

export { CellComponent }
