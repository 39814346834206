import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTriModulesForm } from "./useTriModulesForm"
import { Module } from "../../models/tri-content-modules"

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new Module",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit Module", buttonLabel: "Save" },
}

interface CreateTriModulesModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateTriModulesModal = observer(
  ({ mode, open, closeModal }: CreateTriModulesModalProps) => {
    const {
      newModule,
      errors,
      loading,
      submitted,
      onCancel,
      onSubmit,
      triModuleFormConfig,
    } = useTriModulesForm({ mode, closeModal, isModalOpen: open })

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Module>
            submitted={submitted}
            values={newModule}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT}
            formConfig={triModuleFormConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateTriModulesModal }
