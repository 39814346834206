import React from "react"
import {
  ListItem,
  ListItemText,
  Typography,
  Theme,
  Stack,
  IconButton,
} from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { TriProduct } from "../../models/tri-product"

interface BackupProductItemProps {
  backup: TriProduct
  onClick?: () => void
  handleRemoveBackup: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContainer: {
      backgroundColor: "rgb(229, 228, 226, 0.5)",
      cursor: "pointer",
      marginBottom: theme.spacing(1),
    },
    subContainer: { flexDirection: "row" },
    label: {
      fontSize: theme.spacing(2),
      fontWeight: "bold",
      marginRight: theme.spacing(0.5),
    },
  }),
)

const ListItemSubcontainer = ({ label, value }) => {
  const classes = useStyles()
  return (
    <Stack className={classes.subContainer}>
      <Typography className={classes.label}>{label}: </Typography>
      <Typography>{value}</Typography>
    </Stack>
  )
}

const BackupProductItem = ({
  backup,
  onClick,
  handleRemoveBackup,
}: BackupProductItemProps) => {
  const classes = useStyles()

  return (
    <ListItem onClick={onClick} className={classes.listItemContainer}>
      <ListItemText
        primary={<ListItemSubcontainer label={"Name"} value={backup.name} />}
        secondary={
          <React.Fragment>
            <ListItemSubcontainer
              label={"Display name"}
              value={backup.displayName}
            />

            <ListItemSubcontainer
              label={"Sequence Number"}
              value={backup.backupSequence}
            />
          </React.Fragment>
        }
      />
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          handleRemoveBackup()
        }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </ListItem>
  )
}

export { BackupProductItem }
