import React from "react"
import { observer } from "mobx-react-lite"
import { useRouteMatch } from "react-router-dom"
import { useTheme } from "@material-ui/core/styles"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@mui/material/Divider"
import GroupIcon from "@mui/icons-material/Group"

import { useStores } from "../../models/root-store"
import { SupportPopup, contactSupportSectionHeight } from "../support-popup"
import { useInterval } from "../use-interval"
import {
  PilotDrawerCollapsableGroupItems,
  NavOptionButton,
} from "../pilot-drawer-collapsable-group-items"
import { ReactComponent as Logo } from "../../assets/images/logo_small.svg"
import { ReactComponent as HomeIcon } from "../../assets/images/home.svg"
import { typography } from "../../services/theme/typography"

export const drawerWidth = 260
const drawerContentHeight = `100vh - ${contactSupportSectionHeight}px`
const CHAT_LISTS_REFRESH_RATE = 60000

interface RouteParams {
  affinityGroupId: string
  groupId: string
  coachId: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: 0,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#4070B4",
      position: "fixed",
      padding: `${theme.spacing(4)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    },
    logo: {
      width: theme.spacing(5),
      height: theme.spacing(4.5),
      marginLeft: theme.spacing(1.5),
      marginBottom: theme.spacing(6),
    },
    drawerContent: {
      height: `calc(${drawerContentHeight})`,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    divider: {
      height: 2,
      backgroundColor: "#9FB7D9",
    },
    listContainer: {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    sectionLabel: {
      padding: `${theme.spacing(2)} 0`,
      fontSize: theme.spacing(1.75),
      color: theme.palette.common.white,
      ...typography.circularXXBold,
    },
    nestedBadgeContainer: {
      display: "flex",
      marginLeft: -8,
    },
    badgeContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  }),
)

interface PilotAppDrawerProps {}

export const PilotAppDrawer = observer((props: PilotAppDrawerProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { loginStore, pilotTrainerStore } = useStores()
  const { person } = loginStore
  const {
    coachGroupsMetaList,
    affinityGroups,
    oneOneGroups,
    apiGetPilotCoachGroupsMeta,
    apiGetCurrentCoachMeta,
  } = pilotTrainerStore

  const refreshDirectChatsList = async () => {
    try {
      await apiGetPilotCoachGroupsMeta()
      await apiGetCurrentCoachMeta()
    } catch (error) {
      console.error(error)
    }
  }

  const matchAffinityRoute = useRouteMatch<RouteParams>(
    "/pilot-trainer/:coachId/affinity/:affinityGroupId",
  )

  const { affinityGroupId: currentAffinityGroupId } =
    matchAffinityRoute?.params || {}
  const currentCoach = coachGroupsMetaList.find(
    ({ coach }) => coach.personID === person?.id,
  )?.coach

  useInterval(refreshDirectChatsList, CHAT_LISTS_REFRESH_RATE, true)

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <nav className={classes.drawer} aria-label="pilot coach folders">
      <Drawer
        container={container}
        variant="persistent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <Logo className={classes.logo} />

        <NavOptionButton
          label="My home"
          icon={<HomeIcon />}
          to="/pilot-trainer"
        />

        <Divider
          orientation="horizontal"
          className={classes.divider}
          sx={{ mt: 2, mb: 2 }}
        />

        <div className={classes.drawerContent}>
          {affinityGroups.length > 0 && (
            <>
              <span className={classes.sectionLabel}>Affinity Coaching</span>

              <List className={classes.listContainer}>
                {affinityGroups.map(({ groupID, groupName }) => (
                  <NavOptionButton
                    key={groupID}
                    label={groupName}
                    icon={<GroupIcon />}
                    to={`/pilot-trainer/${currentCoach?.id}/affinity/${groupID}/feed`}
                    selected={currentAffinityGroupId === groupID}
                  />
                ))}
              </List>

              <Divider
                orientation="horizontal"
                className={classes.divider}
                sx={{ mt: 2, mb: 2 }}
              />
            </>
          )}

          {oneOneGroups.length > 0 && (
            <>
              <span className={classes.sectionLabel}>1:1 Coaching</span>

              <List className={classes.listContainer}>
                {oneOneGroups.map((group) => (
                  <PilotDrawerCollapsableGroupItems
                    key={group.groupID}
                    coachID={currentCoach?.id}
                    group={group}
                  />
                ))}
              </List>
            </>
          )}
        </div>

        <SupportPopup />
      </Drawer>
    </nav>
  )
})
