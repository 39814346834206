import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { validate, ValidationRules } from "../../utils/validate"
import { UploadFileModel, UploadFile } from "../upload-file"
import * as customTypes from "../types"

export const DidYouKnowTileModel = types
  .model("DidYouKnowTile")
  .props({
    id: types.identifier,
    friendlyName: types.optional(types.string, ""),
    image: types.optional(types.maybeNull(types.string), ""),
    fact: types.optional(types.maybeNull(types.string), ""),
    source: types.optional(types.maybeNull(types.string), ""),
    active: types.optional(types.boolean, false),
    uploadFiles: types.optional(
      types.model({
        image: types.optional(UploadFileModel, () =>
          UploadFileModel.create({
            id: "image",
          }),
        ),
      }),
      {},
    ),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setFriendlyName(value: string) {
      self.friendlyName = value
    },
    setImage(value: string) {
      self.image = value
    },
    setFact(value: string) {
      self.fact = value
    },
    setSource(value: string) {
      self.source = value
    },
    setIsActive(value: boolean) {
      self.active = value
    },
    setUploadFile(value: UploadFile) {
      self.uploadFiles[value.id] = value
    },

    setContentUrl(id: "image", value: string) {
      self[id] = value
    },

    reset() {
      self.image = ""
      self.fact = ""
      self.active = null
      self.friendlyName = ""
    },
  }))
  .views((self) => ({
    get createDidYouKnowTileErrors() {
      return validate(CREATE_NEW_DID_YOU_KNOW_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_DID_YOU_KNOW_RULES: ValidationRules = {
  friendlyName: {
    presence: { allowEmpty: false, message: "required" },
  },
  image: {
    fileUpload: {},
  },
}

export interface DidYouKnowTile extends Instance<typeof DidYouKnowTileModel> {}
export interface DidYouKnowTileSnapshot
  extends SnapshotOut<typeof DidYouKnowTileModel> {}
