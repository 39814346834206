import React from "react"
import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"
import { useStores } from "../../models/root-store"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useRakCardForm } from "./useRakCardForm"
import { RakCard } from "../../models/rak-card"
interface CreateRakCardModalProps {
  open: boolean
  closeModal: () => void
}

export enum ModalMode {
  ADD_NEW_RAK_CARD,
  EDIT_RAK_CARD,
}

const modalLabels = {
  [ModalMode.ADD_NEW_RAK_CARD]: {
    headerLabel: "Create new rak card",
    buttonLabel: "Create rak card",
  },
  [ModalMode.EDIT_RAK_CARD]: {
    headerLabel: "Edit rak card",
    buttonLabel: "Save",
  },
}

const CreateRakCardModal = observer(
  ({ open, closeModal }: CreateRakCardModalProps) => {
    const { adminMslEventStoreModel } = useStores()
    const { newRakCard } = adminMslEventStoreModel

    const {
      isEditMode,
      loading,
      submitted,
      errors,
      onCancel,
      onSubmit,
      formConfig,
    } = useRakCardForm({ closeModal, isModalOpen: open })

    return (
      <FormModal
        open={open}
        modalTitle={modalLabels[+isEditMode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalLabels[+isEditMode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<RakCard>
            submitted={submitted}
            values={newRakCard}
            errors={errors}
            isEditMode={isEditMode}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    )
  },
)
export { CreateRakCardModal }
