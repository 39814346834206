import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { useFormState } from "../admin-config-form/useFormState"

const usePostForm = ({
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminToolsStore } = useStores()
  const {
    newUserPost,
    resetNewUserPost,
    apiAdminCreatePost,
    isPostModalOpen,
  } = adminToolsStore
  const { createPostErrors: errors } = newUserPost

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen: isPostModalOpen,
    errors,
  })

  const onCancel = () => {
    resetNewUserPost()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }
    try {
      setLoading(true)
      await apiAdminCreatePost()
      setAndShowSnackbar({ text: "Successfully created post !" })
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  return { loading, submitted, onCancel, onSubmit }
}

export { usePostForm }
