import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { Theme, Stack, Button, Typography, Tabs, Tab } from "@material-ui/core"
import { GridColDef, GridCellProps } from "@mui/x-data-grid"
import AddIcon from "@mui/icons-material/Add"

import { useStores } from "../../models/root-store"
import { RakCard } from "../../models/rak-card"
import { CustomGridTable, TableCell } from "../custom-grid-table"
import { typography } from "../../services/theme/typography"
import { CellComponent } from "./cell-component"
import { CreateRakCardModal } from "./create-rak-card-modal"

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "friendlyName",
    headerName: "Friendly Name",
  },
  {
    field: "imageURL",
    headerName: "Image",
  },
  {
    field: "formattedCreatedAt",
    headerName: "Created at",
  },
  {
    field: "formattedUpdatedAt",
    headerName: "Updated at",
  },
  {
    field: "sequence",
    headerName: "Sequence",
  },
  {
    field: "active",
    headerName: "Is Active",
  },
  {
    field: "edit",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]

enum PageTabs {
  Active,
  Archived,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableTrainerCircleImage: {
      width: "100%",
      maxWidth: 120,
    },
    tableCell: {
      width: "30%",
      maxWidth: "30%",
    },
    columnHeader: {
      flex: 1,
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
  }),
)

const AdminRakCardsList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateRakCardModalOpen, toggleCreateRakCardModalOpen] = useState(
    false,
  )
  const [activeTab, setActiveTab] = useState(PageTabs.Active)

  const { adminMslEventStoreModel } = useStores()
  const {
    rakCards,
    apiAdminGetRakCards,
    setNewRakCard,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetRakCards()
      } catch (e) {
      } finally {
        toggleFirstLoading(false)
      }
    }
    initialFetch()
  }, [apiAdminGetRakCards])

  const openCreateRakCardModal = () => {
    toggleCreateRakCardModalOpen(true)
  }

  const closeCreateRakCardModal = () => {
    toggleCreateRakCardModalOpen(false)
  }

  const addNewRakCard = () => {
    openCreateRakCardModal()
  }

  const onEdit = (rakCard: RakCard) => {
    openCreateRakCardModal()
    setNewRakCard(rakCard)
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditRakCardModal={onEdit}
          rakCardID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Rak cards</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addNewRakCard}>
          Add rak card
        </Button>
      </Stack>

      <Tabs
        value={activeTab}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setActiveTab(newValue)
        }}
        aria-label="tabs">
        <Tab label="Active" value={PageTabs.Active} />

        <Tab label="Archived" value={PageTabs.Archived} />
      </Tabs>

      <CustomGridTable
        rows={rakCards.slice().filter((rakCard) => {
          if (activeTab === PageTabs.Active) {
            return rakCard.active
          }
          return !rakCard.active
        })}
        columns={columns}
        aria-label="rak-cards-table"
        cell={MemoTableCell}
        emptyStateLabel="When you create some rak cards, they will appear here"
        loading={firstLoading}
        classes={{
          columnHeader: classes.columnHeader,
        }}
      />

      <CreateRakCardModal
        open={isCreateRakCardModalOpen}
        closeModal={closeCreateRakCardModal}
      />
    </div>
  )
})

export { AdminRakCardsList }
