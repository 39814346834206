import React, { FC, MouseEvent } from "react"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/core"
import clsx from "clsx"
import { useStyles } from "./useStyles"

export enum Status {
  Online = "Online",
  Offline = "Offline",
}

type StatusToggleProps = {
  status: Status
  onChange: (event: MouseEvent<HTMLElement>, newStatus: Status | null) => void
}

const StatusToggle: FC<StatusToggleProps> = ({ status, onChange }) => {
  const classes = useStyles()

  return (
    <ToggleButtonGroup
      className={classes.toggleContainer}
      value={status}
      exclusive
      onChange={onChange}
      aria-label="Online/Offline">
      <ToggleButton
        value={Status.Online}
        className={clsx(classes.toggleButton, {
          [classes.onlineSelected]: status === Status.Online,
        })}>
        Online
      </ToggleButton>

      <ToggleButton
        value={Status.Offline}
        className={clsx(classes.toggleButton, {
          [classes.offlineSelected]: status === Status.Offline,
        })}>
        Offline
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export { StatusToggle }
